import React, { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { totalrecords } from "../sendMessage";

const PopUpModal = ({ show, handleClose, title, body, data }) => {
  const { successfulCount, failureCount } = useMemo(() => {
    if (!data || !Array.isArray(data.data)) {
      console.error("Data is not an array or is undefined:", data);
      return { successfulCount: 0, failureCount: 0 };
    }

    let successfulCount = 0;
    let failureCount = 0;

    data.data.forEach((record) => {
      if (
        record.Success &&
        Array.isArray(record.PhoneNumbers) &&
        record.PhoneNumbers.length > 0
      ) {
        console.log("Successful record:", record);
        successfulCount += record.PhoneNumbers.length;
      } else if (!record.Success) {
        console.log("Failed record:", record);
        failureCount += 1;
      }
    });

    // console.log(
    //   "Calculated counts - Successful:",
    //   successfulCount,
    //   "Failed:",
    //   failureCount
    // );

    return { successfulCount, failureCount };
  }, [data]);

  const renderTitle = () => {
    if (title) {
      return <Modal.Title>{title}</Modal.Title>;
    } else if (data) {
      return successfulCount > 0 ? (
        <Modal.Title>Messages have been successfully delivered</Modal.Title>
      ) : (
        <Modal.Title>Messages are not delivered successfully</Modal.Title>
      );
    }
    return null;
  };

  return (
    <Modal show={show} onHide={() => handleClose(false)} centered>
      <Modal.Header closeButton>{renderTitle()}</Modal.Header>
      <Modal.Body>
        <p>{body}</p>
        <p>Total Records: {totalrecords}</p>
        <p>Successfully Delivered: {successfulCount}</p>
        <p>Failed Deliveries: {failureCount}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpModal;
