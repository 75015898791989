import React, { useContext } from "react";
import { Template_show } from "./templateHeading";
import Card from "./eachTemplate";
import IMAGE from "./images/immmmmage.png";
import TEXT from "./images/text.png";
import VIDEO from "./images/video.png";
import DOCUMENT from "./images/document.png";
import { DataContext } from "./sendMessage";

// Function to filter templates based on the search query
export const Search = (searchQuery, sequence) => {
  let values = Object.values(sequence);

  if (searchQuery.length > 0) {
    values = values.filter((item) =>
      item.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  }

  return values;
};

// Function to create an object with template details
export const create_object = (components) => {
  let headerTxt = "";
  let footerTxt = "";
  let bodyText = "";
  let hasButtons = "";
  let format = "";

  components.forEach((component) => {
    switch (component.type) {
      case "HEADER":
        headerTxt = component.text || "";
        format = component.format || "";
        break;
      case "BODY":
        bodyText = component.text || "";
        break;
      case "FOOTER":
        footerTxt = component.text || "";
        break;
      case "BUTTONS":
        hasButtons = component.buttons || "";
        break;
      default:
        break;
    }
  });

  return [headerTxt, bodyText, footerTxt, hasButtons, format];
};

// Component to render a list of filtered templates
export const OnlyTemplates = ({ filteredTemplates, selected }) => {
  const imageSources = { IMAGE, TEXT, VIDEO, DOCUMENT };

  return (
    <div className="row mb-2">
      {filteredTemplates.map((template) => {
        const components = selected
          ? template.components
          : template.values.components;
        const templateId = selected ? template.id : template.values.id;
        const templateName = selected ? template.name : template.values.name;
        const templateLanguage = selected
          ? template.language
          : template.values.language;
        const [headerTxt, bodyText, footerTxt, hasButtons, format] =
          create_object(components);

        // this is each template card data and is sent to various places to process as cards
        const cardData = {
          templateId,
          templateName,
          templateType: format,
          imageSrc: imageSources[format],
          headerTxt,
          bodyText,
          footerTxt,
          select: selected,
          buttton: hasButtons,
          language: templateLanguage,
        };

        return (
          <div key={templateId} className="col-4">
            <Card cardData={cardData} />
          </div>
        );
      })}
    </div>
  );
};

// Component to render a grid of templates based on search query and category
export const RenderGrid = ({
  category,
  searchQuery,
  setActiveTab,
  visibleCards,
  setVisibleCards,
}) => {
  const select = useContext(DataContext);

  return (
    <div>
      {Object.keys(select).map((row) => {
        const values = Search(searchQuery, select[row]);

        return (
          <div key={row}>
            <Template_show
              row={row}
              values={values}
              category={category}
              setActiveTab={setActiveTab}
              visibleCards={visibleCards}
              setVisibleCards={setVisibleCards}
            />
          </div>
        );
      })}
    </div>
  );
};
