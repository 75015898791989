import React, { useState } from "react";
import xtype from "xtypejs"; // import xtype library
// define failurefile array
export let failurecount = 0;
export let succescount = 0;

function ChartComponent(phonedata, totalrecords) {
  // define an array with initial values of 0
  console.log("i am inside countfailure", phonedata);

  if (
    phonedata[0].Success == true &&
    xtype.type(phonedata[0].PhoneNumbers) === "array"
  ) {
    try {
      let succes;
      if (phonedata[0].PhoneNumbers) {
        succes = phonedata[0].PhoneNumbers;
        console.log("phonedata.PhoneNumbers", succes);

        succescount = succes.length;
        console.log(succescount);
      }
    } catch (exception) {
      console.log(exception);
    }
  }

  failurecount = totalrecords - succescount;

  //console.log("failurecount", phonedata.slice(1));
}
export default ChartComponent;
