import React, { useState, useContext, createContext } from "react";
import Approved from "./showtemplates";
import SelectTemplateHeader from "./selectTemplateHeader";
import Header from "../../header";
import { RecentTemplateData, completeTemplateData } from "../callingTemplates";

// Creating a context
export const DataContext = createContext();

function SendMessage() {
  // Storing recent data
  let recent_data;

  recent_data = RecentTemplateData;

  const [whichtemplate, setwhichtemplate] = useState("Accepted");
  const [category, setcategory] = useState("MARKETING");

  // Checking which template is selected and updating data accordingly
  let Data;
  if (whichtemplate === "Rejected") {
    Data = completeTemplateData.REJECTED;
  } else if (whichtemplate === "Under Review") {
    Data = completeTemplateData.PENDING;
  } else if (whichtemplate === "Accepted") {
    Data = completeTemplateData.APPROVED;
  }

  return (
    // Providing data through context
    <DataContext.Provider value={Data}>
      <div className="col-10 myWidth" id="selectTemplate">
        <div>
          <Header title={"Select Template"} />
          <SelectTemplateHeader
            whichtemplate={setwhichtemplate}
            category={setcategory}
            showtext={category}
          />
        </div>
        {/* Rendering Approved component with category */}
        <Approved category={category} />
      </div>
    </DataContext.Provider>
  );
}

export default SendMessage;
