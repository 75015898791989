import guide from "./sendMessageImages/guide.jpeg";

import React from "react";

function Guidelines({ variables, cardData }) {
  return (
    <div
      className="col ms-5 rounded mb-2 text-white px-4"
      style={{
        backgroundImage: `url(${guide})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginRight: "20px",
        opacity: "90%",
      }}
    >
      {" "}
      <h2 className="my-3">Guidelines: </h2>
      <ol className="list-group">
        <li className="list-group-item">
          1. Number of Variables present in the template body Section are{" "}
          <span className="badge bg-danger">{variables - 1}</span>.
        </li>
        <li className="list-group-item">
          2. The template you have selected is an{" "}
          <span className="badge bg-info">{cardData.templateType}</span>{" "}
          Template.
        </li>
        <li className="list-group-item">
          3. Please upload XLSX file type to send messages.
        </li>
        <li className="list-group-item">
          4. We make sure sending data without breaks even when your data has
          issues providing the best in class message systems
        </li>
      </ol>
    </div>
  );
}

export default Guidelines;
