import TemplateType from "../components/Messagingpanel/createTemplate/templateCategory";
import Sidebar from "../components/sidebar";

function CreateTemplate() {
  return (
    <div className="row">
      <Sidebar />
      {/* template type is nothing but template category selecting page */}
      <TemplateType />
    </div>
  );
}
export default CreateTemplate;
