import "./styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, createContext } from "react";
import PrivateRoute from "./Authentication/privateRoute";
import Login from "./Authentication/loginPage/login";
import { publicRoutes, privateRoutes } from "./allroutes";
import FetchMetrics from "./components/metrics/fetchMetrics";

// Create the context for metrics
export const mymetrics = createContext();

export default function App() {
  const [auth, setAuth] = useState(false); // This useState is to find if the user is authenticated
  const [unauth, setUnauth] = useState(false);
  const [metrics, setMetrics] = useState(); // State to hold metrics data

  return (
    <BrowserRouter>
      {/* Wrap your routes in the mymetrics provider */}
      <mymetrics.Provider value={[metrics, setMetrics]}>
        <Routes>
          {publicRoutes.map((x, index) => {
            return <Route key={index} path={x.path} element={x.element} exact />;
          })}

          {/* Below routes are only accessible if the login is successful */}
          <Route
            path="/login"
            element={<Login auth={auth} setAuth={setAuth} unauth={setUnauth} />} // passing 2 hooks here
          />

          {/* The main dashboard Route */}
          <Route
            exact
            path="/"
            element={
              <>
                <FetchMetrics /> {/* Fetch metrics data on dashboard load */}
                <PrivateRoute isAuthenticated={auth} />
              </>
            }
          >
            {/* The nested private routes */}
            {privateRoutes.map((x, index) => {
              return <Route key={index} path={x.path} element={x.element} exact />;
            })}
          </Route>
        </Routes>
      </mymetrics.Provider>
    </BrowserRouter>
  );
}
