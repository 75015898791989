import latestNotification from "../components/images/latestNotifications.svg";
import { persondata } from "../Authentication/FetchedUserData";
function Header(props) {
  return (
    <header className="row border" id="header">
      <div class="d-flex flex-row">
        <div className="p-2 flex-grow-1">
          <h4 className="mainName mt-3">
            {props.title || "Welcome to Your Dashboard"}
          </h4>
        </div>

        <div className="p-2 mt-2 headerCompany">
          {" "}
          <h6>
            <b>{persondata.companyname}</b>
          </h6>
          <p>Id : {persondata.WABA_ID}</p>
        </div>
        <div className="p-2">
          {/* <img
            src="https://curiouswebservices.in/wp-content/uploads/2022/09/cropped-CURIOUS-1.webp"
            alt="curiouswebservices"
            className="companyImage"
          /> */}
        </div>

        <div className="p-2">
          {" "}
          <img
            src={latestNotification}
            className="notificationHead"
            alt="notification"
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
