import React, { useState, useRef } from "react";
import { Button, Container, Row, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import PopUpModal from "./ui/popUpModal";
import useVisibility from "../../setVisibleHook";
function FileUpload({ fileType, setMedia }) {
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const { show, hide, toggle, tisVisible } = useVisibility(false);
  // this handles all the file operations and errors
  const allowedImageExtensions = ["jpg", "jpeg", "png"];
  const maxSizeImage = 5 * 1024 * 1024; // 5MB
  const maxSizeVideo = 16 * 1024 * 1024; // 16MB

  const showMessage = (msg) => {
    setMessage(msg);
    setMedia(null);
    toggle();
    fileInputRef.current.value = null;
  };

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileType === "Image") {
      if (!allowedImageExtensions.includes(fileExtension)) {
        showMessage("Please upload a .jpg, .jpeg, or .png file.");
        return false;
      }
      if (file.size > maxSizeImage) {
        showMessage("Please upload an image file smaller than 5MB.");
        return false;
      }
    } else if (fileType === "Video") {
      if (fileExtension !== "mp4") {
        showMessage("Please upload an .mp4 file.");
        return false;
      }
      if (file.size > maxSizeVideo) {
        showMessage("Please upload a video file smaller than 16MB.");
        return false;
      }
    }
    return true;
  };

  // sets the media to headersample
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validateFile(selectedFile)) {
      setMessage("");
      setMedia(selectedFile);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <h4 className="my-2">Upload Your {fileType} File</h4>
        <Form.Group>
          <Form.Control
            type="file"
            onChange={onFileChange}
            ref={fileInputRef}
          />
        </Form.Group>
        {message && (
          <PopUpModal
            showModal={tisVisible}
            handleClose={toggle}
            message={message}
          />
        )}
      </Row>
    </Container>
  );
}

export default FileUpload;
