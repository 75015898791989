import React from "react";
import { persondata } from "../../Authentication/FetchedUserData";
import { Card } from "react-bootstrap";

function GeneralUserDetails() {
  const fields = [
    { label: "Company Name", value: persondata.companyname },
    { label: "Phone No ID", value: persondata.phoneNo_id },
    { label: "App ID", value: persondata.appId },
    { label: "Email", value: persondata.EMAIL },
    { label: "WABA ID", value: persondata.WABA_ID },
    {
      label: "Created Date",
      value: new Date(persondata.createdDateTime).toLocaleString(),
    },
    { label: "Phone No", value: persondata.phone_no },
  ];

  return (
    <div className="container my-4">
      <div className="row">
        {fields.map((field, index) => (
          <div className="col-md-4 mb-3" key={index}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>{field.label}</Card.Title>
                <Card.Text>{field.value || "N/A"}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GeneralUserDetails;
