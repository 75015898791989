import Image1 from "./registrationimages/conversations.svg";
import Image2 from "./registrationimages/Analytics-pana 1datadriven.svg";
import Image3 from "./registrationimages/Inbox cleanup-pana 1.svg";
import Image4 from "./registrationimages/Verified-bro 1green tick.svg";
const carouselBlock = [
  {
    image: Image1,
    title: "Unlock the Power of Your Brand",
    content:
      "Unleash Success and Irresistible Growth with Bulk Messaging, reaching lakhs of customers for phenomenal results",
  },
  {
    image: Image2,
    title: "Data-Driven Discoveries",
    content:
      "Ignite your potential with analytics, unlocking actionable insights and making optimal decisions for remarkable outcomes.",
  },
  {
    image: Image3,
    title: "Engage and Empower",
    content:
      "Experience the Joy of AI-Powered Conversations with Delightful Interactions that Boost Service Purchases.",
  },
  {
    image: Image4,
    title: "Get Green Tick for your Business",
    content:
      "Establish Trust, Build Credibility, and Instill Confidence with our Exclusively Verified Green Tick",
  },
];

export const registrationInputsPrimary = [
  {
    type: "text",
    name: "companyname",
    label: "Company Name",
    placeholder: "enter your compnay name",
  },
  {
    type: "text",
    name: "username",
    label: "User Name",
    placeholder: "enter your user name",
  },
  {
    type: "email",
    name: "email",
    label: "Email Address",
    placeholder: "enter your email address",
  },
  {
    type: "tel",
    name: "phone",
    label: "Mobile Number",
    placeholder: "enter your mobile number",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    placeholder: "enter your Password",
  },
];

export const registrationInputsSecondary = [
  {
    type: "text",
    name: "website",
    placeholder: "Enter your website url",
    label: "Website Address",
  },
  {
    type: "text",
    name: "industry",
    placeholder:
      "Enter your industry type so that we can provide custom experience",
    label: "Your Industry",
  },
];

export default carouselBlock;
