import React from "react";
import "../loginsuccess.css";
import forgotImg from "../../images/forgot-password-pana-2.svg";
import CreateRoute from "./routeMaker";

function Textbox() {
  return (
    <CreateRoute
      mainImg={forgotImg}
      title={" We have sent the reset link to your email!"}
      btnText="Check Your Email"
      finalText="Back To Login"
      navigateOne="#"
      navigateTwo="/login"
    />
  );
}

export default Textbox;
