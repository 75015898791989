import { Show_recent } from "../selectTemplate/Recenttemplates";

// this function fetches metrics data the metricData is nothing but metrics data
// Function to sort and extract unique templates from the message object
const sortAndExtractUniqueTemplates = (metricData) => {
  // Log the initial state for debugging
  console.log("Understanding this: ", Show_recent, metricData);

  // Initialize an array to store unique templates
  let uniqueTemplates = [];

  // Reverse the keys to process the most recent messages first
  Object.keys(metricData)
    .reverse()
    .forEach((timestamp) => {
      const template = metricData[timestamp];
      const templateString = Object.keys(template)[0];

      // Add template to uniqueTemplates if it's not already present and we have less than 10 templates
      if (
        !uniqueTemplates.includes(templateString) &&
        uniqueTemplates.length < 10
      ) {
        uniqueTemplates.push(templateString);
      }
    });

  // If we have any unique templates, call Show_recent with them
  if (uniqueTemplates.length > 0) {
    return Show_recent(uniqueTemplates);
  }

  return [];
};

export default sortAndExtractUniqueTemplates;
