import { useEffect, useState, createContext, useContext } from "react";
import "./metrics.css";
import CalenderSelector from "./calender";
import FetchMetrics from "./fetchMetrics";
import MetricCards from "./metricCards";
import MetricGraph from "./metricsGraph";
import TemplatesSented from "./templatesSented";
import TemplatesAndGraph from "./templates&graph";
import TemplateAnalytics from "./templateAnalytics";
import { mymetrics } from "../../App";

// this is the central component of metrics page
function Metrics() {
  // this below usestate is used to capture the templates from fetchmetrics
  const [mainMetrics, setMainMetrics] = useContext(mymetrics); // used for create context
  const [myDate, setMyDate] = useState();
  const [metricCards, setMetricsCards] = useState(null);
  const [templateNames, setTemplateNames] = useState();
  const [formattedDateString, setFormattedDateString] = useState("");

  // console.log("the main metrics are ", mainMetrics);
  // the below useEffect is used to find the state update and send that to totalRatios
  useEffect(() => {
    mainMetrics
      ? mainMetrics["All"]
        ? totalRatios(mainMetrics)
        : console.log("not recieved")
      : "";
  }, [mainMetrics]);

  console.log("the main metrics are ", mainMetrics);

  useEffect(() => {
    // Function to format date to a readable string
    const formatDate = (date) =>
      date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

    // Check if myDate is provided and is a valid array
    if (myDate && myDate.length === 2) {
      const [startDate, endDate] = myDate;
      setFormattedDateString(
        `From ${formatDate(startDate)} to ${formatDate(endDate)}`
      );
    } else {
      // Get the current date
      const currentDate = new Date();

      // Get the date 30 days before the current date
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - 30);

      // Create the formatted date string for the last 30 days
      setFormattedDateString(
        `From ${formatDate(pastDate)} to ${formatDate(currentDate)}`
      );
    }
  }, [myDate]);

  let successTotal;
  let failureTotal;
  let readTotal;
  let responseTotal;

  //This function is used to seperate the recieved data and pass on
  // this function is helpful to create metric cards
  function totalRatios(data) {
    successTotal = 0;
    failureTotal = 0;
    readTotal = 0;
    responseTotal = 0;

    // Combine data from all
    for (const timestamp in data["All"]) {
      const entry = data["All"][timestamp];
      for (const key in entry) {
        const eventData = entry[key];
        for (const event of eventData) {
          successTotal += event.success || 0;
          failureTotal += event.failure || 0;

          for (const readTimestamp in event.read) {
            readTotal += event.read[readTimestamp] || 0;
          }
        }
      }
    }

    const templateCounts = {};
    // This is for getting out template names in the campaigns
    for (const timestamp in data["All"]) {
      const responseEntry = data["All"][timestamp];
      for (const key in responseEntry) {
        templateCounts[key] = (templateCounts[key] || 0) + 1;
      }
    }
    setTemplateNames(templateCounts);

    responseTotal = successTotal / (readTotal || 1); // this is wrong

    setMetricsCards([successTotal, failureTotal, readTotal, responseTotal]);
  }

  // Below code makes the ui visible based on the URL
  const currentPathname = window.location.pathname;
  let stylus;
  if (currentPathname === "/status") {
    stylus = "d-flex ms-3 mt-5 calenderMetrics justify-content-around";
  } else {
    stylus = "d-flex ms-3 mt-5 calenderMetrics hide justify-content-around";
  }
  return (
    <div id="metrics">
      <div className="metricItems">
        <h5 className="text-center mt-3">
          These Analytics are from{" "}
          <span className="bg-success p-1 text-white">
            {formattedDateString}{" "}
          </span>
        </h5>
        <div className={stylus}>
          <MetricCards templateData={metricCards} />
          <CalenderSelector setDate={setMyDate} />
          {myDate && <FetchMetrics dates={myDate} />}
        </div>
        <MetricGraph date={myDate} />
        <div className="d-flex">
          <div className="ms-5 my-4">
            <h3 className="mb-3">Templates used</h3>
            <TemplatesSented templates={templateNames} />
          </div>
          <div className="ms-5 my-4 ">
            <h3>Template Analytics</h3>
            <p className="ms-1">Each template usage rates </p>
            <TemplatesAndGraph templates={templateNames} />
          </div>
        </div>
        <TemplateAnalytics />
      </div>
    </div>
  );
}

export default Metrics;
