import logo from "../../images/wbsLogo.svg";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

// this component creates routes that show the notifications
// of login and registraion flows
function CreateRoute(props) {
  const navigate = useNavigate();
  return (
    <div className="forgotPassword">
      <img src={logo} alt="" className="mx-4 my-4" />
      <div className="text-center">
        <img src={props.mainImg} alt="" className="forgotImg" />
        <h2 className="routeText">{props.title}</h2>
        <button
          className="btn largeButton"
          onClick={() => navigate(props.navigateOne)}
        >
          {props.btnText}
        </button>
        <br />
        <h6 className="mt-4" onClick={() => navigate(props.navigateTwo)}>
          <span className="mx-2 ms-4 blueText"> {props.finalText} </span>
          <BsArrowRight className="blueText" />
        </h6>
      </div>
    </div>
  );
}

export default CreateRoute;
