import React, { useRef, useState } from "react";
import { Card as BootstrapCard, Modal, Button } from "react-bootstrap";
import filemoji from "./sendMessageImages/insidediv.png";
import filedownloadsymbol from "./sendMessageImages/filedownload.png";
import close from "./sendMessageImages/Close.png";
import { Failurebox, Successbox } from "./Sucessandfailure";
import useVisibility from "../../setVisibleHook";
import PopUpModal from "./ui/PopUpModal";

const FileUploadSection = ({
  fileName,
  setFileName,
  faisuc,
  handleFileUpload,
  sendExcelData,
}) => {
  const inputRef = useRef();
  const { tisVisible, toggle } = useVisibility();
  const [showWarningModal, setShowWarningModal] = useState(false);

  const resetFileInput = () => {
    inputRef.current.value = null;
    setFileName("File name");
  };
  const handleCardClick = () => {
    inputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file, "dropOn");
  };

  const handleSubmit = () => {
    if (fileName === "File name") {
      setShowWarningModal(true);
    } else {
      sendExcelData();
      resetFileInput();
    }
  };

  return (
    <div className="col-11 ms-5">
      {/* Header part of the uploader */}
      <div>
        <div className="row">
          <div className="col text-start">
            <img
              src={filedownloadsymbol}
              alt="File Download Symbol"
              className="img-fluid m-1"
            />
            <span>{fileName}</span>
          </div>
          <div className="col text-end">
            <Button
              onClick={resetFileInput}
              className="bg-dark"
              style={{ marginRight: "10px" }}
            >
              Remove file
            </Button>
          </div>
        </div>
        {/* Header part of the uploader ends*/}

        <div className="row" style={{ marginRight: "25px" }}>
          <div
            className={`ms-3 p-3 py-5 mt-3 col-12 dashed text-center ${
              tisVisible ? "disabled" : ""
            }`}
            onClick={handleCardClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div>
              <img className="mb-3 img-fluid" src={filemoji} alt="File Emoji" />
              <input
                type="file"
                onChange={handleFileUpload}
                ref={inputRef}
                hidden
              />
            </div>
            <BootstrapCard.Title>
              Drag And Drop Your File Here
            </BootstrapCard.Title>
            <div className="mt-1">We only support xlsx filetype</div>
            <div className="row justify-content-center align-items-center p-1">
              {tisVisible && (
                <Modal
                  show={tisVisible}
                  onHide={toggle}
                  centered
                  size="md"
                  backdrop="static"
                  keyboard={false}
                  className="align-items-center justify-content-center"
                >
                  <div className="imageright">
                    <img
                      src={close}
                      className="small-image"
                      onClick={toggle}
                      alt="Close"
                    />
                  </div>
                  {/* {faisuc === "200" && <Successbox />}
                  {faisuc === "500" && <Failurebox />} */}
                </Modal>
              )}
            </div>
          </div>
        </div>
        {/* <FileUploadCard /> */}
        <div className="row">
          <div className="col-10 mt-2 d-flex justify-content-end align-items-center">
            <Button
              onClick={handleSubmit}
              className="bg-dark"
              style={{ marginRight: "-180px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <PopUpModal
        show={showWarningModal}
        handleClose={setShowWarningModal}
        title={"No File Uploaded"}
        body="Please upload an .xlsx file before submitting."
      />
    </div>
  );
};

export default FileUploadSection;
