// this is a custom hook i have created to toggle elements visibility

import { useState } from "react";

function useVisibility(initialValue = false) {
  const [tisVisible, setIsVisible] = useState(initialValue);
  // console.log(tisVisible, "tisvisibllelelellelle");

  const show = () => {
    // console.log(tisVisible);
    setIsVisible(true);
  };
  const hide = () => setIsVisible(false);
  const toggle = () => setIsVisible((prevValue) => !prevValue);

  return { tisVisible, show, hide, toggle };
}

export default useVisibility;
