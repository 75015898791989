import { persondata } from "./Authentication/FetchedUserData";
const domain = "https://wbsconnections.online/";
export const apiRoutes = {
  login: domain + "api/check-user-credentials",
  // login: "http://localhost:8082/api/check-user-credentials",
  forgotPassword: domain + "api/update-user?operationType=changePassword",
  registration: domain + "api/create-user",
  checkUser: domain + "api/get-user", // this api is used to check whether the user exists or not in the database
  facebookAuth: domain + "api/check-user-credentials", // we will check whether the email exists in our database or not
  prepareComponents: domain + "api/prepare-components",
  genAI: domain + "api/openai-chat-completions?max_tokens=500",
  metrics: domain + "api/fetch-wbs-metrics",
  fetchTemplates: domain + "api/fetch-templates",
  sendMessage: domain + "api/send-message",
  chats: domain + "api/fetch-wbs-chat-data",
  metaMessageUrl: `https://graph.facebook.com/v19.0/${persondata.phoneNo_id}/messages`,
  uploadFileCreateTemplate: `http://localhost:8082/path/`,
  createTemplateLatest: domain + `create-message-templates`,
};
