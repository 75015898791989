import React from "react";
import { OnlyTemplates } from "./onlyTemplates";
import { handleShow } from "./seeAll";

export const Length = [
  { TEXT: 3 },
  { IMAGE: 3 },
  { VIDEO: 3 },
  { DOCUMENT: 3 },
];

export const Template_show = ({
  row,
  values,
  category,
  setActiveTab,
  visibleCards,
  setVisibleCards,
}) => {
  const handleClick = () => {
    handleShow(row, visibleCards, setVisibleCards);
    setActiveTab(row);
  };

  const makeTextBetter = (text) =>
    text
      .replace(/_/g, " ")
      .replace(/template/gi, " template")
      .replace(/\b\w/g, (c) => c.toUpperCase());

  const filteredTemplates = values
    .filter((template) => template.category === category.category)
    .slice(0, visibleCards[row]);

  if (visibleCards[row] > 0 && filteredTemplates.length > 0) {
    Length[row] = values.length;

    return (
      <div key={row}>
        <div className="heading-container mb-2">
          <h2>{makeTextBetter(row)} Template</h2>
          <a href="#" onClick={handleClick} className="see-all-link me-4">
            {visibleCards[row] === 3 ? "See All" : "See Less"}
          </a>
        </div>
        <div className="row">
          <OnlyTemplates filteredTemplates={filteredTemplates} selected />
        </div>
      </div>
    );
  }

  return null;
};
