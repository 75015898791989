import React, { useContext } from "react";
import { mymetrics } from "../../App";
function TemplateAnalytics() {
  const [mainMetrics, setMainMetrics] = useContext(mymetrics);
  // console.log(mainMetrics["All"]);

  return <div>templateAnalytics</div>;
}

export default TemplateAnalytics;
