import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./loginForm";
import loginData from "./loginContent";
import "./loginsuccess.css";
import CarouselCard from "../registrationAuth/carouselCards";
import WbsLogo from "./../images/wbsLogo.svg";

// My Instructions

// Get the email Address from the input box
// When email address is empty and someone presses the forgot button
//show the alert of enter email
//companyname,emailid,(permennt token >>access token),
//wabid

function Login(props) {
  console.log("I am goooddd", props.auth);
  if (props.auth == false)
    return (
      <div>
        <div className="loginBlock mx-3 mt-3 row">
          <div className="loginData">
            <img src={WbsLogo} className="mainLogo" />
            <Form setAuth={props.setAuth} unauth={props.unauth} />
          </div>
        </div>
      </div>
    );
}
export default Login;
