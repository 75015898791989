import noAccount from "../../images/accountDontExist.svg";
import CreateRoute from "./routeMaker";
//it is for users who don't have account
function NoAccount() {
  return (
    <CreateRoute
      mainImg={noAccount}
      title={"It Looks like you don’t have an account"}
      btnText="Create your account"
      finalText="Back To Login"
      navigateOne="/userregistration"
      navigateTwo="/login"
    />
  );
}

export default NoAccount;
