import PropTypes from "prop-types";
import Picker from "@emoji-mart/react";
import ActionButtons from "./templateButtonsFeature";
import { FloatingLabel, Form } from "react-bootstrap";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import TextGenAI from "./textGenerativeAI";

// this component is the body of the form where we enter the message along with the footer

function BodyForm(props) {
  // Here the props are being destructured
  const {
    handleForm,
    checkForVariable,
    addVariable,
    handleEmojiButtonClick,
    handleEmojiClick,
    emoji,
    setVariables,
    variables,
    removeBodyVariable,
    data,
    setData,
    bodyRef,
    insertAi,
  } = props;

  // this function is used to add bold and italic feature to the form
  // for the function to work the user needs to send the bold and italic symbols
  const applyFormatting = (ref, startChar, endChar) => (event) => {
    event.preventDefault();
    const textarea = ref.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.slice(0, startPos) +
      startChar +
      currentValue.slice(startPos, endPos) +
      endChar +
      currentValue.slice(endPos);

    textarea.value = newValue;
    textarea.focus();
    textarea.setSelectionRange(startPos + 1, startPos + 1);
  };
  return (
    <div style={{ marginTop: "33px" }}>
      <div className="d-flex justify-content-between">
        <div>
          <h5>Body Section</h5>
          <p>Enter the text for your template here</p>
        </div>
        <TextGenAI
          bold={applyFormatting}
          italic={applyFormatting}
          mainData={data}
          setMainData={setData}
          setVariables={setVariables}
          bodyRef={bodyRef}
          variable={addVariable}
          insertAi={insertAi}
        />
      </div>

      <textarea
        className="form-control "
        id="body"
        placeholder="Enter the text you want to send"
        rows="7"
        ref={bodyRef}
        name="bodyText"
        value={data.body}
        onKeyUp={checkForVariable}
        onChange={handleForm}
      ></textarea>
      <div className="text-end">
        <button
          className="btn mx-1 mt-2 formBtns"
          onClick={handleEmojiButtonClick}
        >
          😀
        </button>
        <button
          className="btn mx-1 mt-2 formBtns"
          onClick={applyFormatting(bodyRef, "*", "*")}
        >
          B
        </button>
        <button
          className="btn mt-2 formBtns"
          onClick={applyFormatting(bodyRef, "_", "_")}
        >
          I
        </button>
        <button
          className="btn addVariable ms-2 mt-2 text-white"
          onClick={addVariable(bodyRef)}
        >
          <AiOutlinePlusSquare className="me-2" />
          Add Variable <span>{20 - variables.length}</span>
        </button>
      </div>

      {emoji && (
        <div className="text-end emojiPicker mt-3">
          <Picker onEmojiSelect={handleEmojiClick} />
        </div>
      )}
      {variables.length > 0 && (
        <div className="my-3 pb-4" id="variableSamples">
          <div className="mt-3">
            <h3>Sample data for variables</h3>
            <p>
              Samples are used to inform the system about the types of data you
              intend to use for variables when sending a message.
            </p>
          </div>

          {variables.map((variable, index) => (
            <div key={index} className="d-flex">
              <div className="mt-4">Sample variable {index + 1} :</div>
              <FloatingLabel
                controlId={`bodySampleText${index + 1}`}
                label={`enter a sample value`}
                className="bodyVariable mt-2 mx-2 w-50"
              >
                <Form.Control
                  name={`bodySampleText${index + 1}`}
                  type="text"
                  placeholder="Enter sample data"
                  defaultValue={data[`bodySampleText${index + 1}`]}
                  onChange={handleForm}
                />
              </FloatingLabel>
              <div>
                {index === variables.length - 1 && (
                  <button
                    onClick={removeBodyVariable(
                      `bodySampleText${index + 1}`,
                      "body"
                    )}
                    className="btn mt-3  ms-5 formBtns removeBodyVariable"
                  >
                    <FaTrashAlt className="mb-1" />{" "}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <h5 className="mt-3">Footer (Optional):</h5>
      <p>Add a short line of text to the bottom of your message</p>
      <FloatingLabel
        controlId="footer"
        label="Footer Text"
        className="bodyVariable mt-4 spacing"
      >
        <Form.Control
          name="footer"
          type="text"
          placeholder="Enter footer"
          onChange={handleForm}
        />
      </FloatingLabel>
      <ActionButtons handleForm={handleForm} setData={setData} data={data} />
    </div>
  );
}

// This proptypes lets me know what type of prop i'm sending and also gives me an error when a prop is missing

BodyForm.propTypes = {
  handleForm: PropTypes.func.isRequired,
  checkForVariable: PropTypes.func.isRequired,
  addVariable: PropTypes.func.isRequired,
  insertAi: PropTypes.func.isRequired,
  handleEmojiButtonClick: PropTypes.func.isRequired,
  handleEmojiClick: PropTypes.func.isRequired,
  emoji: PropTypes.bool.isRequired,
  bodyRef: PropTypes.object.isRequired,
  variables: PropTypes.array.isRequired,
  removeBodyVariable: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default BodyForm;
