import React, { useEffect, useState } from "react";
import useVisibility from "../../components/setVisibleHook";
import GoogleBtn from "../googleAuth";
import { v4 as uuidv4 } from "uuid";
import logo from "./../images/wbsLogo.svg";
import axios from "axios";
import { useNavigate, NavLink, Navigate, Link } from "react-router-dom";
import FloatingInput from "../floatingInput";
import FbLogin from "../facebookAuth";
import { apiRoutes } from "../../apiRoutes";
import {
  registrationInputsPrimary,
  registrationInputsSecondary,
} from "./regContent";

function RegistrationForm(props) {
  // use state for the entire
  const [googleSignIn, setGoogleSignIn] = useState(false);
  const [emptyInput, setEmptyInput] = useState("");
  const [form, setForm] = useState({
    username: "",
    googleID: null,
    email: "",
    phone: "",
    password: null,
    companyname: "",
    website: "",
    location: "",
    industry: "",
    correlationId: uuidv4(),
  });
  const navigate = useNavigate();
  // if form gets updated by anymeans i.e google Auth or normal user inputs this will console.log
  useEffect(() => {
    if (form) {
      console.log("The Updated Form", form);
      console.log("empty form", emptyInput);
    }
  }, [form, emptyInput]);

  // this is a custom hook which is useful to hide and show stuff
  const { tisVisible, toggle, hide, show } = useVisibility();

  // if we get data from google auth this func will get you to the second form
  const socialAuth = (data) => {
    if (data === "success") {
      setGoogleSignIn(true);
      show();
    }
  };

  // this function handles form changes
  const handleForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // when user fills the form manually this function will work
  const nextStep = (e) => {
    e.preventDefault();
    Object.entries(form).forEach(([key, value]) => {
      if (value === "") {
        setEmptyInput((x) => [...x, key]);
      }
    });
    if (
      form.username.length === 0 ||
      form.companyname.length === 0 ||
      form.email.length === 0 ||
      form.password.length === 0 ||
      form.phone.length === 0
    ) {
      console.log("empty data exists");
    } else {
      console.log("length satisfied");
      const config6 = {
        method: "post",
        url: apiRoutes.checkUser, // this api is used to check whether the user exists or not in the database
        data: JSON.stringify({
          email: form.email,
          corelationId: uuidv4(),
        }),
      };
      axios(config6)
        .then((res) => {
          console.log("response of tesing data in server", res);
          if (res.status === 200) {
            // if the user exists this will work
            alert("Your account already exists please visit the login page");
          }
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 404) {
            // if the user doesnt exist in the database we will move him to the second form
            show();
          }
        });
      setEmptyInput("");
    }
  };
  // when the form is full the form is submitted to the database and the user is registered
  const submitForm = (e) => {
    e.preventDefault();
    setEmptyInput("");
    Object.entries(form).forEach(([key, value]) => {
      if (value === "") {
        setEmptyInput((x) => [...x, key]);
      }
    });
    if (
      form.companyname.length === 0 ||
      form.industry.length === 0 ||
      form.location.length === 0 ||
      form.website.length === 0 ||
      form.phone.length === 0
    ) {
      console.log("the secondary form has null values");
    } else {
      const config = {
        method: "post",
        url: apiRoutes.registration,
        data: JSON.stringify(form),
      };
      axios(config)
        .then((res) => {
          console.log("sending to database", res);
        })
        .catch((err) =>
          console.log("error while sending data to database", err)
        );
    }
  };

  return (
    <div id="regForm" className="text-center col-6">
      <img src={logo} className="" />
      <div className="registrationContent">
        <h2>Create Your Account</h2>
        <p className="fw-bold">
          Fill in the details below to start your{" "}
          <span className="text-success fw-bold">7 Day FREE trial</span>. No
          Credit Card needed.
        </p>
      </div>

      {/* intial form will disappear when the user is validated */}
      <div style={tisVisible ? { display: "none" } : { display: "block" }}>
        {/* this is the google auth component */}
        {/* //We will be sending the form to store the google auth data
          // we will be sending the location so that code works different location
          // handlegoogledata func is sent to ensure we are getting data when we get we will show the send form */}

        <div class="row justify-content-center">
          <div class="col-2">
            {" "}
            <GoogleBtn
              setForm={setForm}
              myLocation="registration"
              handleGoogleEvent={socialAuth}
              btnName={"signup with google"}
            />
          </div>
          <div class="col-2">
            {" "}
            <FbLogin
              location="registration"
              handleFbEvent={socialAuth}
              setForm={setForm}
            />
          </div>
        </div>

        <p className="my-4"> -------------------- or ---------------------</p>
      </div>
      {/* primary form */}
      <form
        action="#"
        style={tisVisible ? { display: "none" } : { display: "block" }}
      >
        {registrationInputsPrimary.map((x, index) => {
          return (
            <FloatingInput
              key={index}
              type={x.type}
              name={x.name}
              label={x.label}
              placeholder={x.placeholder}
              emptyState={emptyInput}
              onChange={handleForm}
            />
          );
        })}

        <button className="btn text-white mt-4" onClick={nextStep}>
          Next Step
        </button>
        <div className="text-center">
          <div className="noAccount mt-2 pt-3 flex  d-flex">
            <p>Already have account?</p>
            <a
              className="text-success ms-2 "
              onClick={() => navigate("/login")}
            >
              <b>Login Now</b>
            </a>
          </div>
        </div>
      </form>

      {/* secondary Form */}

      <form
        action="#"
        style={tisVisible ? { display: "block" } : { display: "none" }}
      >
        {googleSignIn && (
          <FloatingInput
            type={registrationInputsPrimary[0].type}
            name={registrationInputsPrimary[0].name}
            label={registrationInputsPrimary[0].label}
            placeholder={registrationInputsPrimary[0].placeholder}
            emptyState={emptyInput}
            onChange={handleForm}
          />
        )}
        {registrationInputsSecondary.map((x, index) => {
          return (
            <FloatingInput
              key={index}
              type={x.type}
              name={x.name}
              label={x.label}
              placeholder={x.placeholder}
              emptyState={emptyInput}
              onChange={handleForm}
            />
          );
        })}
        {/* <input
          type="text"
          name="website"
          placeholder="Enter your website url"
          className="form-control mt-3"
          onChange={handleForm}
        />
        <input
          type="text"
          name="industry"
          placeholder="Enter your industry type so that we can provide custom experience"
          className="form-control mt-3"
          onChange={handleForm}
        /> */}
        <textarea
          name="location"
          id=""
          cols="30"
          rows="3"
          placeholder="Enter your company location"
          className="form-control mt-3"
          onChange={handleForm}
        />
        {googleSignIn && (
          <FloatingInput
            type={registrationInputsPrimary[3].type}
            name={registrationInputsPrimary[3].name}
            label={registrationInputsPrimary[3].label}
            placeholder={registrationInputsPrimary[3].placeholder}
            emptyState={emptyInput}
            onChange={handleForm}
          />
        )}

        <button className="btn text-white my-3" onClick={submitForm}>
          Submit Now
        </button>
      </form>
    </div>
  );
}

export default RegistrationForm;
