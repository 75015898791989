import Image from "./loginRoutes/Marketing consulting-pana (3) 1.svg";
const loginData = [
  {
    image: Image,
    title: "Amplify Your Business with Unbeatable Read Rates!",
    content:
      "Supercharge your business with our game-changing application. Experience the Automation of Notifications, Reach Customers with Engaging Campaigns, and Achieve Unprecedented Read Rates. ",
  },
  {
    title: "Welcome Back!",
    content:
      "Supercharge Your Marketing Efforts. Log in Now for Unmatched Results!",
  },
];

export default loginData;
