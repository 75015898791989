import React from "react";
import Header from "../header";
import GeneralUserDetails from "./generalUserDetails";
import Billing from "./billing";
import "./userAccount.css";
import ChangePassword from "./changePassword";
import ContactForm from "./contactUs";
function UserCentral() {
  return (
    <div className="col-10 myWidth">
      <Header title={"My Account Page"} />
      <GeneralUserDetails />
      <div className="row">
        <Billing />
        <ChangePassword />
      </div>
      <ContactForm />
    </div>
  );
}

export default UserCentral;
