import React, { useState } from "react";
import Header from "../../header";
import "./createTemplate.css";
import About from "./assets/About.svg";
import Goal from "./assets/Goal.svg";
import Fingerprint from "./assets/fingerprint.svg";
import arrow from "./assets/arrow.svg";
import thumbnail from "./assets/youtubeThumbnail.png";
import { useNavigate } from "react-router-dom";

function TemplateType() {
  const navigate = useNavigate();

  // this function creates the template categories
  const CategoryCreator = (props) => {
    const handleSelect = () => {
      navigate("/createTemplate/templatecreator", {
        state: { myCategory: props.name.toUpperCase() },
      });
    };

    return (
      <div className="col-4 ourcard">
        <div className="templateCard text-center">
          <h6 className="title">{props.name}</h6>
          <img src={props.img} className="cardImg" alt="text template" />
          <p className="mt-3">{props.desc}</p>
          <button
            className="btn btn-success mb-2 buttonType"
            onClick={handleSelect}
          >
            {props.btn}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="col-10 myWidth templateType body"
      style={{ backgroundColor: "#D2FFD6" }}
    >
      <Header title="Create Template" />
      <div>
        <div className="row ms-5 titleHead">
          <div className="col-6 ">
            <h2>Elevate your template creation with expert guidance</h2>
            <p className="mt-3">
              Access expert guidance, step-by-step tutorials, and valuable tips.
              Unleash your creativity, learn new techniques, and design stunning
              templates with confidence and ease.
            </p>
          </div>
          <div className="col-1 arrow ">
            <img src={arrow} alt="arrow" />
          </div>
          <div className="col-4">
            <img src={thumbnail} alt="yt Thumbnail" className="ytThumbnail" />
          </div>
        </div>
      </div>
      <h4 className="chooseTemplateSub">Choose a Template</h4>
      <div className="row">
        <CategoryCreator
          img={Goal}
          desc="Craft Tailored Templates to Boost Business Exposure and Expand Your Reach."
          name="Marketing"
          btn="Use Template"
        />

        <CategoryCreator
          img={About}
          desc="Elevate Your Business with Dynamic, Interactive Templates that Inform and Engage."
          name="Utility"
          btn="Use Template"
        />

        <CategoryCreator
          img={Fingerprint}
          desc="Secure customer interactions with WhatsApp authentication for reliable data validation."
          name="Authentication"
          btn="Use Template"
        />
      </div>
    </div>
  );
}

export default TemplateType;
