import Templates from "../components/Messagingpanel/selectTemplate/sendMessage";
import Sidebar from "../components/sidebar";

function AllTemplates() {
  return (
    <div className="row">
      <Sidebar />
      {/* template type is nothing but template category selecting page */}

      <Templates />


    </div>
  );
}
export default AllTemplates;
