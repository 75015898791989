export const sortElementsByTime = (profileData) => {
  // Convert the data object to an array of entries
  const sortedPhoneNumbers = Object.keys(profileData).map((key) => {
    const lastMessage = profileData[key][profileData[key].length - 1];
    return {
      phoneNumber: key,
      lastMessageTime: new Date(lastMessage.RESPONSE_UPDATED_DATETIME),
    };
  });

  // Sort the array based on lastMessageTime
  sortedPhoneNumbers.sort((a, b) => {
    return b.lastMessageTime - a.lastMessageTime; // Sort in descending order
  });

  return sortedPhoneNumbers;
};
