import { Length } from "./templateHeading";

export const handleShow = (type, visibleCards, setVisibleCards) => {
  // console.log("type", type);

  if (type === "ALL") {
    setVisibleCards({
      TEXT: 3,
      IMAGE: 3,
      VIDEO: 3,
      DOCUMENT: 3,
    });
  } else {
    const updatedVisibleCards = {
      TEXT: 0,
      IMAGE: 0,
      VIDEO: 0,
      DOCUMENT: 0,
    };
    updatedVisibleCards[type] = Length[type];
    setVisibleCards(updatedVisibleCards);
  }

  // console.log(visibleCards, "visibleCards");
};
