import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartDataLabels
);

function TemplatesAndGraph({ templates }) {
  // Check if templates object is not empty
  const hasTemplates = templates && Object.keys(templates).length > 0;

  if (!hasTemplates) {
    return <p>No templates available to display.</p>; // Render a message if no templates are available
  }

  const data = {
    labels: Object.keys(templates),
    datasets: [
      {
        label: "Template Usage",
        data: Object.values(templates),
        backgroundColor: [
          "rgba(13, 193, 107, 0.2)",
          "rgba(13, 193, 107, 0.3)",
          "rgba(13, 193, 107, 0.4)",
          "rgba(13, 193, 107, 0.5)",
          "rgba(13, 193, 107, 0.6)",
          "rgba(13, 193, 107, 0.7)",
          "rgba(13, 193, 107, 0.8)",
          "rgba(13, 193, 107, 0.9)",
        ],
        borderColor: "rgba(13, 193, 107, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        formatter: (value) => `${value}`,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="templateAnalytics">
      <Bar data={data} options={options} />
    </div>
  );
}

export default TemplatesAndGraph;
