import axios from "axios";
import React, { useEffect, useState } from "react";
import { persondata } from "../../../Authentication/FetchedUserData"; // Importing fetched user data
import { apiRoutes } from "../../../apiRoutes"; // Importing API routes
import Calendar from "react-calendar";

// Component to fetch data
export const Outdata = () => {
  let [tempdata, settempdata] = useState(0); // State to store fetched data

  // Axios configuration for fetching templates
  let config = {
    method: "post",
    url: apiRoutes.fetchTemplates, // API route for fetching templates
    data: JSON.stringify({
      PERMANENT_TOKEN: persondata.permanenttoken, // User's permanent token
      WABA_ID: persondata.WABA_ID, // User's WABA ID
    }),
  };

  // Effect hook to fetch data when component mounts
  useEffect(() => {
    // console.log("calling axios");
    axios(config)
      .then((res) => {
        console.log(res.data); // Log fetched data
        settempdata(res.data); // Set fetched data to state
      })
      .catch((err) => {
        console.log(err); // Log error if any
      });
  }, []); // Dependency array empty to run effect only once when component mounts

  return tempdata; // Return fetched data
};

// Function to format date
export const formatDate = (date) => {
  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
  const day = date.getDate().toString().padStart(2, "0");

  // Format the date as desired (e.g., YYYY-MM-DD)
  return `${year}-${month}-${day}`;
};

// this is an unknown functionality used somewhere
// Component to fetch templates
export const template = () => {
  let [showtemplates, setshowtemplates] = useState(0); // State to store fetched templates

  const currentDate = new Date(); // Get current date

  // Calculate the date 10 days ago
  const tenDaysAgo = new Date(currentDate);
  tenDaysAgo.setDate(currentDate.getDate() - 10);

  // Format both dates
  const formattedCurrentDate = formatDate(currentDate); // Format current date
  const formattedTenDaysAgo = formatDate(tenDaysAgo); // Format ten days ago

  // Effect hook to fetch templates when component mounts
  useEffect(() => {
    const config = {
      method: "post",
      url: apiRoutes.metrics, // API route for fetching metrics
      data: JSON.stringify({
        wabaID: persondata.WABA_ID, // User's WABA ID
        from_dateTime: formattedTenDaysAgo + " 00:00:00", // Start date for metrics data
        to_dateTime: formattedCurrentDate + " 23:59:59", // End date for metrics data
      }),
    };

    axios(config)
      .then((res) => {
        setshowtemplates(res.data); // Set fetched templates to state
      })
      .catch((error) => console.log(error)); // Log error if any
  }, []); // Dependency array empty to run effect only once when component mounts

  return showtemplates; // Return fetched templates
};
