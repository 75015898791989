import React from "react";
import { FaExternalLinkAlt, FaPhoneAlt } from "react-icons/fa";

const iconStyle = { fontSize: "0.95em" };

const CardButton = ({ buttonData }) => {
  return (
    <div className="footer justify-content-center my-1">
      <hr style={{ margin: "0" }} />
      <div className="d-flex p-2 justify-content-around">
        {buttonData.buttton.map((button, index) => {
          const { type, text, url, phone_number } = button;
          const linkProps =
            type === "URL"
              ? { href: url, target: "_blank", rel: "noopener noreferrer" }
              : { href: `tel:${phone_number}` };

          return (
            <div key={index} className="footer-container">
              <a {...linkProps} className="text-success cardBtn">
                {type === "URL" ? (
                  <FaExternalLinkAlt style={iconStyle} />
                ) : (
                  <FaPhoneAlt style={iconStyle} />
                )}
                {"   "}
                <span className="m-1 mt-2">{text} </span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardButton;
