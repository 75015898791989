import Header from "./header";
import React from "react";
import RocketTriangle from "./assets/rocketTriangle.svg";
import SendMessage from "./sendMessage";
import Whyus from "./whyUs";
import { useNavigate } from "react-router-dom";

function TrailDashboard() {
  const nav = useNavigate();
  console.log("reached");
  return (
    <div className="col-8">
      <div>
        <div className="mx-3">
          <div className="row cardBG ">
            <div className="col-9 mt-4 mx-3 text-white">
              <h3 className="titleText">
                Register now and unlock full potential..!
              </h3>
              <p>We will help you to register your business on META</p>
              <div className="buttons">
                <a href="/" className="btn btn-info">
                  Call us
                </a>
                <a href="/" className="btn">
                  Whatsapp Us
                </a>
                <a href="/" className="btn">
                  Get an live Demo
                </a>
              </div>
            </div>
            <div className="col-2 rocket">
              <img src={RocketTriangle} alt="" />
            </div>
          </div>
        </div>
        <SendMessage />
      </div>
      <button
        type="button"
        className="btn btn-secondary text-center mb-2 "
        style={{
          marginLeft: "70px",
          marginRight: "70px",
          borderRadius: "10px",
        }}
        onClick={() => {
          nav("/login");
        }}
      >
        Back to login page
      </button>
    </div>
  );
}

export default TrailDashboard;
