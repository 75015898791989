import React from 'react'
import UserCentral from '../components/userAccount/userCentral'
import Sidebar from '../components/sidebar'

function MyAccount() {
    return (
        <div className='row'>
            <Sidebar />
            <UserCentral />
        </div>
    )
}

export default MyAccount