import { useState, useEffect } from "react";
import Success from "./assets/successSubmission.png";
import Failure from "./assets/failedSubmission.png";
import Topright from "./assets/righttop.svg";
import TopLeft from "./assets/lefttop.svg";
import Bottomleft from "./assets/bottomLeft.svg";
import Bottomright from "./assets/bottomRight.svg";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

function FormSubmitNotification({ status, show, setShow }) {
  console.log(show);
  const [response, setResponse] = useState({
    state: "",
    message: "",
    image: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (status?.data?.responseFromWhatsAppClousAPi?.status) {
      const apiStatus = status.data.responseFromWhatsAppClousAPi.status;
      if (apiStatus === "PENDING") {
        setResponse({
          state: "Template Submitted",
          message:
            "Congratulations your Template is successfully submitted to Meta",
          image: Success,
        });
      } else if (apiStatus === "REJECTED") {
        setResponse({
          state: "Template not Submitted",
          message: "Your template was rejected by Meta.",
          image: Failure,
        });
      } else {
        setResponse({
          state: "Template Submitted",
          message:
            "Congratulations your Template is successfully submitted to Meta",
          image: Success,
        });
      }
    } else if (status?.status === 400 || status?.status === 500) {
      setResponse({
        state: "Template not Submitted",
        message: data.message.error.error_user_msg,
        image: Failure,
      });
    } else {
      setResponse({
        state: "Template Submitted",
        message:
          "Congratulations your Template is successfully submitted to Meta",
        image: Success,
      });
    }
  }, [status]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <img src={response.image} alt="submission status" className="myImg" />
        <h3 className="mt-5">{response.state}</h3>
        <p className="mt-3">{response.message}</p>
        <Button
          className="btn btn-primary text-white mt-2"
          onClick={() => navigate("/createTemplate")}
        >
          Create Template
        </Button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default FormSubmitNotification;
