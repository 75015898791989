import React, { useContext } from "react";
import { mymetrics } from "../../../App";
import { RecentTemplateData } from "../../Messagingpanel/callingTemplates";
import { OnlyTemplates } from "../../Messagingpanel/selectTemplate/onlyTemplates";
import MetricGraph from "../../metrics/metricsGraph";

function Media(props) {
  const [gettingMetrics, setGettingMetrics] = useContext(mymetrics);
  return (
    <>
      <div id="dashboard">
        <div className="row text-center">
          <div className="mediaBack text-white  bg-success">
            <h2 className="mx-3">Dashboard Centre</h2>
            <p className="mx-3">
              Hey there! Welcome to our Dashboard – where managing your WhatsApp
              marketing is a breeze!
            </p>
          </div>
        </div>
        <div className="recentTemplates mt-3">
          <h3>Recently Used Templates</h3>
          <div className="row mt-3">
            {RecentTemplateData.length > 0 && (
              <div className="row">
                <OnlyTemplates
                  filteredTemplates={RecentTemplateData}
                  selected={false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="analytics">
          <h3 className="mb-3">Recent Analytics - last 30 days</h3>
          <MetricGraph />
        </div>
      </div>
    </>
  );
}

export default Media;
