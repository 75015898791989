import React, { useState, useContext } from "react";
import { BiSearch } from "react-icons/bi";
import { RenderGrid } from "./onlyTemplates";
import { handleShow } from "./seeAll";
import "./template.css";
import { DataContext } from "./sendMessage";

const Approved = (props) => {
  const select = useContext(DataContext);
  const category = props;

  const [activeTab, setActiveTab] = useState("ALL");
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCards, setVisibleCards] = useState({
    TEXT: 3,
    IMAGE: 3,
    VIDEO: 3,
    DOCUMENT: 3,
  });

  // Function to handle tab click and update visible cards
  const handleTabClick = (tab) => {
    const tabUpperCase = tab.toUpperCase();
    setActiveTab(tabUpperCase);
    handleShow(tabUpperCase, visibleCards, setVisibleCards);
  };

  // If DataContext is available, render the main content
  if (select) {
    return (
      <div id="templateBodySection" className="bg-white">
        <div
          id="templateTypeSelector"
          className="d-flex justify-content-center"
        >
          <ul className="d-flex justify-content-center">
            {["All", "Text", "Image", "Video", "Document"].map((tab, index) => (
              <li
                key={index}
                className={`mx-4 px-3 text-center accepted rounded ${
                  activeTab === tab.toUpperCase() ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="ms-5">
            <div className="input-group">
              <input
                type="text"
                value={searchQuery}
                className="form-control"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search templates"
                aria-label="Search templates"
                aria-describedby="basic-addon1"
              />
              <div className="input-group-prepend">
                <span className="input-group-text py-2" id="basic-addon1">
                  <BiSearch />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <RenderGrid
            category={category}
            searchQuery={searchQuery}
            setActiveTab={setActiveTab}
            visibleCards={visibleCards}
            setVisibleCards={setVisibleCards}
          />
        </div>
      </div>
    );
  }

  // If DataContext is not available, show a message
  return (
    <div className="bg-white p-2">
      <h1 className="mt-5 ms-1 mx-4 text-center">No templates are available</h1>
    </div>
  );
};

export default Approved;
