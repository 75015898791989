import { useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, NavLink, Navigate, Link } from "react-router-dom";
import { persondata } from "./FetchedUserData";
import { FcGoogle } from "react-icons/fc";
import { apiRoutes } from "../apiRoutes";

function GoogleBtn({
  setForm,
  myLocation,
  handleGoogleEvent,
  btnName,
  setAuth,
}) {
  // intialising the props which we get from the registration
  const [googleUser, setGoogleUser] = useState();
  const [personData, setPersonData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [receivedData, setRecievedData] = useState({
    email: "",
    googleID: "",
    username: "",
  });
  // This gets the user access token when he clicks on gmail
  const googleReg = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse); // response is stored in this usestate
      console.log("New User:", codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  // when google user gets the data this works
  const navigate = useNavigate();
  // when google user gets the data this works
  useEffect(() => {
    if (googleUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("the main Resonse", res);
          const config6 = {
            method: "post",
            url: apiRoutes.facebookAuth, // we will check whether the email exists in our database or not
            data: JSON.stringify({
              email: res.data.email,
              googleID: res.data.id,
              corelationId: uuidv4(),
            }),
          };

          // if the location is registration
          if (myLocation === "registration") {
            console.log(myLocation);
            axios(config6)
              .then((res) => {
                // if the account exists
                console.log("response of tesing data in server", res);
                if (res.status === 200) {
                  alert(
                    "Your account already exists please visit the login page"
                  );
                }
              })
              .catch((error) => {
                console.log(error.response.status);
                if (
                  error.response.status === 500 ||
                  error.response.status === 404 ||
                  error.response.status === 401 ||
                  error.response.status === 400
                ) {
                  // if the account doesnt exist we will store it to usestate
                  setRecievedData({
                    email: res.data.email,
                    username: res.data.name,
                    googleID: res.data.id,
                    password: null,
                  });
                  handleGoogleEvent("success"); // send the success to the registration form
                }
              });
          } else if (myLocation === "login") {
            console.log("from google auth login", myLocation);
            axios(config6)
              .then((res) => {
                // if the account exists
                console.log("response of tesing data in server", res);

                if (res.status === 200) {
                  setPersonData(res.data[0]);
                  setAuth(true);
                  // saving person data
                  persondata.companyname = res.data[0].COMPANY_NAME;
                  persondata.EMAIL = res.data[0].EMAIL;
                  persondata.permanenttoken = res.data[0].PERMANENT_TOKEN;
                  persondata.WABA_ID = res.data[0].WABA_ID;
                  persondata.phone_no = res.data[0].PHONE_NO;
                  persondata.phoneNo_id = res.data[0].PHONENO_ID;
                  setErrorMessage(null);
                  if (res.data[0].ACCOUNT_TYPE === "paid") {
                    console.log("paid");
                    navigate("/");
                  } else if (res.data[0].ACCOUNT_TYPE === "trail") {
                    console.log("trial");
                    navigate("/daystrial");
                  }
                }
              })
              .catch((error) => {
                console.log("error.response", error);
                console.log(error.response.status);
                if (
                  error.response.status === 500 ||
                  error.response.status === 404 ||
                  error.response.status === 401 ||
                  error.response.status === 400
                ) {
                  // if the account doesnt exist we will store it to usestate
                  navigate("/noAccount");
                  // handleGoogleEvent("success"); // send the success to the registration form
                }
              });
          }
        })
        .catch((err) => console.log("The main error", err));
    }
  }, [googleUser]);

  // if recieveddata is udpated it will be pushed to form for the registration page
  useEffect(() => {
    if (receivedData) {
      setForm((prevForm) => ({
        ...prevForm,
        ...receivedData,
      }));
    }
  }, [receivedData, setForm]);

  return (
    <button className="googleBtn" onClick={googleReg}>
      <FcGoogle className="googleImg" />
    </button>
  );
}
export default GoogleBtn;
