import React, { useState, useEffect } from "react";
import { MakingCards } from "./makingCards";
import { MdNavigateNext } from "react-icons/md";

function TemplatesSented({ templates }) {
  const [loading, setLoading] = useState(true);
  const [templateAna, setTemplateAna] = useState();
  console.log(templates);
  // Set loading to false once templates are received
  useEffect(() => {
    if (templates && Object.keys(templates).length > 0) {
      setLoading(false);
    }
  }, [templates]);

  // console.log("the templates used in this campaign", templates);

  const templateAnalytics = (e) => {
    console.log(e);
    setTemplateAna(e);
  };

  return (
    <>
      {loading ? (
        <p>Loading...</p> // You can replace this with any loading indicator
      ) : (
        <div>
          {Object.entries(templates).map(([key, count], index) => (
            <div>
              <MakingCards
                key={index}
                cardType={key}
                class={
                  "d-flex border border-secondary bg-white justify-content-between py-3 px-2 my-2 rounded"
                }
                fontSize={"fs-6"}
                type={"templatesSent"}
                nextBtn={<MdNavigateNext />}
                clicked={templateAnalytics}
              />
            </div>
          ))}{" "}
        </div>
      )}
    </>
  );
}

export default TemplatesSented;
