import { useEffect, useState } from "react";
import { MakingCards } from "./makingCards";
// this are the card components of the metrics page
function MetricCards({ templateData }) {
  const [cardsUpdate, setCardsUpdate] = useState(null);
  const [totalMetrics, setTotalMetrics] = useState(5);
  const [loading, setLoading] = useState(true);
  console.log("recieving Data", templateData);

  useEffect(() => {
    if (templateData) {
      if (templateData[0]) {
        // i'm adding up the success and failure to get total here
        setTotalMetrics(templateData[0] + templateData[1]);
        setCardsUpdate(templateData); // from this we are extracting all ratios
        setLoading(false);
      }
    }
  }, [templateData]);

  return (
    <div id="metricCards" className="text-start">
      <h3>Activity Monitor</h3>
      <p>Here's what happened from the last month</p>
      <MakingCards
        cardType={"Success"}
        cardUpdate={cardsUpdate?.[0]}
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />
      <MakingCards
        cardType={"Failure"}
        cardUpdate={cardsUpdate?.[1]}
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />
      <MakingCards
        cardType={"Read"}
        cardUpdate={cardsUpdate?.[2]}
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />
      {/* <MakingCards
        cardType={"Response"}
        cardUpdate={Math.round(cardsUpdate?.[3])}
      /> */}
    </div>
  );
}

export default MetricCards;
