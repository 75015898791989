import React from "react";
import {
  Route,
  Redirect,
  Routes,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";
// it is to check wherther authorized user or not
const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoute;
