import React, { useState } from "react";

const NonBulkMessenger = ({ onDataSend }) => {
  const initialUserData = [
    {
      phoneNumber: "",
      headerSample: "",
      variable1: "",
      variable2: "",
      variable3: "",
      variable4: "",
      variable5: "",
    },
  ];

  const [userData, setUserData] = useState(initialUserData);

  const handleChange = (index, key, value) => {
    const updatedUserData = [...userData];
    updatedUserData[index] = { ...updatedUserData[index], [key]: value };
    setUserData(updatedUserData);
  };

  const addRow = () => {
    setUserData([
      ...userData,
      {
        phoneNumber: "",
        headerSample: "",
        variable1: "",
        variable2: "",
        variable3: "",
        variable4: "",
        variable5: "",
      },
    ]);
  };

  const removeRow = (index) => {
    const updatedUserData = [...userData];
    updatedUserData.splice(index, 1);
    setUserData(updatedUserData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("hello i am in hanle submit non bulk messanger");
    // console.log(userData, "userData");
    onDataSend(userData);
    setUserData(initialUserData); // Clear the form after submission
  };

  return (
    <div className="non-bulk-messenger mx-5 mb-4">
      <h3>Non-Bulk Messenger</h3>
      <p>Fill in the details and click "Send Message" to send your data.</p>
      <form onSubmit={handleSubmit}>
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Phone Numbers</th>
              <th>Header Media</th>
              <th>Variable 1</th>
              <th>Variable 2</th>
              <th>Variable 3</th>
              <th>Variable 4</th>
              <th>Variable 5</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={data.phoneNumber}
                    onChange={(e) =>
                      handleChange(index, "phoneNumber", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={data.headerSample}
                    onChange={(e) =>
                      handleChange(index, "headerSample", e.target.value)
                    }
                  />
                </td>
                {[
                  "variable1",
                  "variable2",
                  "variable3",
                  "variable4",
                  "variable5",
                ].map((key, colIndex) => (
                  <td key={colIndex}>
                    <input
                      type="text"
                      className="form-control"
                      value={data[key]}
                      onChange={(e) => handleChange(index, key, e.target.value)}
                    />
                  </td>
                ))}
                <td>
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => removeRow(index)}
                    >
                      x
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-start">
          <button type="button" className="btn btn-dark" onClick={addRow}>
            Add Row
          </button>
          <button type="submit" className="btn btn-success ms-3">
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default NonBulkMessenger;
