import { Outdata, template } from "./selectTemplate/fetchtemplates";
import SortDataComponent from "./selectTemplate/objsort";

export let completeTemplateData = "";
export let RecentTemplateData = "";

export default function Alltemlatedata() {
  completeTemplateData = Outdata();
  let recent_data = template();
  try {
    if (completeTemplateData.APPROVED) {
      // Accepteddata = completeTemplateData.APPROVED;
      if (recent_data["Not From Webhooks"]) {
        let data = recent_data["Not From Webhooks"];
        RecentTemplateData = SortDataComponent(data);
      }
    }
  } catch (Exception) {
    console.log(Exception);
  }
  return;
}
