import axios from "axios";
import { useState, useEffect } from "react";
import { LoginSocialFacebook } from "reactjs-social-login";
import { BsFacebook } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { persondata } from "./FetchedUserData";
import { useNavigate, NavLink, Navigate, Link } from "react-router-dom";
import { apiRoutes } from "../apiRoutes";

// the Facebook Authentication
function FbLogin({ location, handleFbEvent, setForm, setAuth }) {
  const [receivedData, setRecievedData] = useState({
    email: "",
    googleID: "",
    username: "",
  });

  const navigate = useNavigate();

  // if recieveddata is udpated it will be pushed to form for the registration page
  useEffect(() => {
    if (receivedData) {
      setForm((prevForm) => ({
        ...prevForm,
        ...receivedData,
      }));
    }
  }, [receivedData, setForm]);

  return (
    <LoginSocialFacebook
      appId={process.env.REACT_APP_FB_APPID}
      onResolve={(res) => {
        console.log(res);
        const config6 = {
          method: "post",
          url: apiRoutes.facebookAuth,
          data: JSON.stringify({
            email: res.data.email,
            googleID: res.data.id,
            corelationId: uuidv4(),
          }),
        };
        if (location === "registration") {
          axios(config6)
            .then((res) => {
              if (res.status === 200) {
                alert(
                  "Your account already exists please visit the login page"
                );
              }
            })
            .catch((error) => {
              console.log("My error is ", error);
              if (
                error.response.status === 500 ||
                error.response.status === 404 ||
                error.response.status === 401 ||
                error.response.status === 400
              ) {
                // if the account doesnt exist we will store it to usestate
                setRecievedData({
                  email: res.data.email,
                  username: res.data.name,
                  googleID: res.data.id,
                  password: null,
                });
                handleFbEvent("success"); // send the success to the registration form
              }
            });
        } else if (location === "login") {
          axios(config6)
            .then((res) => {
              // if the account exists
              console.log("response of tesing data in server", res);

              if (res.status === 200) {
                setPersonData(res.data[0]);
                setAuth(true);
                // saving person data
                persondata.companyname = res.data[0].COMPANY_NAME;
                persondata.EMAIL = res.data[0].EMAIL;
                persondata.permanenttoken = res.data[0].PERMANENT_TOKEN;
                persondata.WABA_ID = res.data[0].WABA_ID;
                persondata.phone_no = res.data[0].PHONE_NO;
                persondata.phoneNo_id = res.data[0].PHONENO_ID;
                setErrorMessage(null);

                //setAccountType(res.data[0].ACCOUNT_TYPE);
                if (res.data[0].ACCOUNT_TYPE === "paid") {
                  console.log("paid");
                  navigate("/");
                } else if (res.data[0].ACCOUNT_TYPE === "trail") {
                  console.log("trial");
                  navigate("/daystrial");
                }
              }
            })
            .catch((error) => {
              console.log("error.response", error);
              console.log(error.response.status);
              if (
                error.response.status === 500 ||
                error.response.status === 404 ||
                error.response.status === 401 ||
                error.response.status === 400
              ) {
                // if the account doesnt exist we will store it to usestate
                navigate("/noAccount");
                // handleGoogleEvent("success"); // send the success to the registration form
              }
            });
        }
      }}
      onReject={(res) => {
        console.log("the reseason for rejection", res);
      }}
    >
      <button className="googleBtn">
        <BsFacebook className="googleImg" />
      </button>
    </LoginSocialFacebook>
  );
}
export default FbLogin;
