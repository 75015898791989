import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import "../template.css";

const ThreeDotsMenu = (props) => {
  console.log("Imageeeeeeee", props);

  const handleClose = () => {
    props.setOptionsVisible(false);
  };
  return (
    <div>
      <Modal show={props.isOptionsVisible} onHide={handleClose}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <IoArrowBackCircleSharp
            style={{ fontSize: "3rem", color: "343A40" }}
            onClick={handleClose}
          />
          <h4 style={{ marginLeft: "0.5rem" }}>{props.name}</h4>
        </div>

        <div className="bg-light p-2">
          <p>{props.body}</p>
        </div>

        <div class="d-flex bd-highlight mt-5">
          <div class="me-auto p-2 bd-highlight">
            <button className="blackButton">Delete</button>
          </div>
          <div class="p-2 bd-highlight">
            <button className="blackButton">Edit</button>
          </div>
          <div class="p-2 bd-highlight">
            <button className="blackButton">Select</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ThreeDotsMenu;
