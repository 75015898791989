// below function generates the card ui and all its logic
export const MakingCards = (props) => {
  const classNames = `d-flex metricCards ps-3 bg-white justify-content-between mediaBack `;

  return (
    <div className={props.class || classNames}>
      <h5
        className={`ps-2 ` + props.fontSize}
        onClick={() => props.clicked(props.cardType)}
      >
        {props.cardType}
      </h5>
      {props.type === "metricCards" && (
        <h6 className="pe-3">
          {!props.loading ? (
            props.cardUpdate + "/" + props.totalMetrics
          ) : (
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {/* {templateData?.[0] ? props.cardUpdate + "/" + totalMetrics : <div class="spinner-grow text-primary" role="status">
  <span class="sr-only"></span>
</div>} */}
        </h6>
      )}
      {props.type === "templatesSent" && (
        <h4 onClick={props.clicked}> {props.nextBtn} </h4>
      )}
    </div>
  );
};
