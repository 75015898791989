import React from "react";
import { persondata } from "../../Authentication/FetchedUserData";
import { Card } from "react-bootstrap";

function Billing() {
  return (
    <div className="col">
      <Card className="billingCard mx-2">
        <Card.Body>
          <Card.Title>Pending Amount</Card.Title>
          <Card.Text>This is the overall due amount for your account</Card.Text>
          <Card.Text>{persondata.payableAmount}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Billing;
