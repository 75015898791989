import CreateRoute from "./routeMaker";
import upgrade from "../../images/upgradeAccount.svg";
// this page is shown to trail expired users
function Trailexpired() {
  return (
    <CreateRoute
      title="Your trial has ended,please upgrade to a paid account"
      btnText="Upgrade Your Plan"
      mainImg={upgrade}
      finalText="Contact Us"
      navigateTwo="#"
      navigateOne="#"
    />
  );
}
export default Trailexpired;
