import React from "react";
import ChartComponent from "../components/metrics/metrics";
import Header from "../components/header";
function Status(props) {
  return (
    <div className={props.class}>
      <Header title={"The Metrics page"} />
      <ChartComponent />
    </div>
  );
}

export default Status;
