import Main from "../components/chatSystem/main"
import Header from "../components/header"

function ChatBot() {
    return (
        <div className="col-10 myWidth body">
            <Header />
            <Main />
        </div>

    )
}
export default ChatBot