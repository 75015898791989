import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ThreeDotsMenu from "./ui/preview";
import CardButton from "./ui/button";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";

// This component renders each template in send message
const Card = ({ cardData }) => {
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const navigate = useNavigate();

  const {
    imageSrc,
    templateName,
    templateType,
    bodyText,
    headerTxt,
    footerTxt,
    select,
    templateId,
    button,
  } = cardData;

  const handleButtonClick = () => {
    setHoveredItemId(null);
    navigate("/components/Messagingpanel/sendmessages/phonedata/", {
      state: { myCategory: cardData },
    });
  };

  const makeTextBetter = (inputText) => {
    const betterText = inputText.replace(/_/g, " ");
    return (
      betterText.charAt(0).toUpperCase() +
      betterText
        .slice(1)
        .toLowerCase()
        .replace(/template/g, " template")
    );
  };

  // Function to format body text with line breaks
  const formatTextWithLineBreaks = (text) => {
    return text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  //here there are 2 variations of sliced text 1 for
  // general view and the other for preview
  const slicedBodyText = bodyText.slice(0, 100);
  const formattedBody = formatTextWithLineBreaks(bodyText);
  const slicedFormattedBody = formatTextWithLineBreaks(slicedBodyText);

  return (
    <div className="card mb-3">
      <div className="templateHeader mt-3">
        <div className="d-flex">
          <div className="ms-3">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={imageSrc}
                alt="Body Image"
                className="templateCardImage"
                style={{ width: "3vmax", height: "3vmax" }}
              />
              <h4 style={{ marginLeft: "1rem" }}>
                {makeTextBetter(templateName)}
              </h4>
            </div>

            <div className="d-flex align-items-center mt-3">
              <span
                className="bg-success text-white p-1 templateType"
                style={{ fontSize: "12px", marginRight: "10px" }}
              >
                {makeTextBetter(templateType)} type
              </span>
              <span
                className="bg-success text-white p-1 templateType"
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => setOptionsVisible(true)}
              >
                Preview
              </span>
            </div>
          </div>

          {isOptionsVisible && (
            <ThreeDotsMenu
              name={templateName}
              body={formattedBody}
              setOptionsVisible={setOptionsVisible}
              isOptionsVisible={isOptionsVisible}
            />
          )}
        </div>
      </div>

      <hr />

      <div
        className="templateBody"
        onMouseEnter={select ? () => setHoveredItemId(templateId) : null}
        onMouseLeave={select ? () => setHoveredItemId(null) : null}
        onClick={select ? handleButtonClick : null}
      >
        {templateType === "TEXT" ? (
          <div>
            {headerTxt && (
              <div className="templateContentHead">
                <p>{headerTxt.slice(0, 80)} ...</p>
              </div>
            )}
            <div className="templateContentBody">
              <p>{slicedFormattedBody}</p>
            </div>
            {footerTxt && (
              <div className="templateContentFooter">
                <p>{footerTxt.slice(0, 80)} ...</p>
              </div>
            )}
          </div>
        ) : (
          <div className="templateContentBody">
            <p>{bodyText.replace(/\n/g, " ").slice(0, 180)}</p>
          </div>
        )}
      </div>

      {button && <CardButton buttonData={cardData} />}
    </div>
  );
};

Card.propTypes = {
  cardData: PropTypes.shape({
    imageSrc: PropTypes.string.isRequired,
    templateName: PropTypes.string.isRequired,
    templateType: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    headerTxt: PropTypes.string,
    footerTxt: PropTypes.string,
    select: PropTypes.bool,
    templateId: PropTypes.string,
    button: PropTypes.any,
    language: PropTypes.string,
  }).isRequired,
};

export default Card;
