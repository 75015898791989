import React from "react";
function SendMessage() {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mx-3 py-3 px-5 phNumber">
      <h3 className="myCardTitle mt-3">Send A Test Message to Your Number</h3>
      <form
        action="#"
        id="sendingForm"
        className="my-5"
        onSubmit={handleSubmit}
      >
        <label htmlFor="Phone">+919110733204</label>
        <label htmlFor="top" style={{ fontWeight: "700" }}>
          {" "}
          To
        </label>
        <input type="number" name="Phone" id="" />
        <a href="/" className="btn btn-success bg-success text-white mx-3">
          Send Now
        </a>
      </form>
    </div>
  );
}

export default SendMessage;
