//here we store user data who logid to our application
export const persondata = {
  companyname: "werr",
  EMAIL: "qeqewr",
  permanenttoken: "wudif",
  WABA_ID: "1309",
  phone_no: "48380808509",
  phoneNo_id: "",
  appId: "",
  payableAmount: "",
  createdDateTime: "",
  accountStatus: "",
};
