import React, { createContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Header from "../components/header";
import DashboardCentre from "../components/dasboard/MainDashboard/dashboardCentre";
import RightBar from "../components/dasboard/MainDashboard/rightSidebar";
import "../components/dasboard/dashboard.css";
import Alltemlatedata from "../components//Messagingpanel/callingTemplates";

function Dashboard(props) {
  Alltemlatedata();

  return (
    <div className={props.class}>
      <Header />
      <DashboardCentre class="myWidth" />
    </div>
  );
}

export default Dashboard;
