import CarouselCard from "./carouselCards";
import "./registration.css";
import carouselBlock from "./regContent";
import RegistrationForm from "./registrationForm";
// import Carousel from "react-elastic-carousel";

function UserReg() {
  return (
    <div className="row">
      <div className="col-6 regHead">
        {/* <Carousel showArrows={false} transitionMs={1000} enableAutoPlay={true}>
          {carouselBlock.map((x) => {
            return (
              <CarouselCard
                image={x.image}
                title={x.title}
                content={x.content}
              />
            );
          })}
        </Carousel> */}
      </div>

      <RegistrationForm />
    </div>
  );
}

export default UserReg;
