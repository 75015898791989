import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { apiRoutes } from "../../apiRoutes";
import { persondata } from "../../Authentication/FetchedUserData";
import { chats } from "./main";

function FetchChats() {
  const [myChats, setMyChats] = useContext(chats);

  useEffect(() => {
    const config = {
      method: "post",
      url: apiRoutes.chats,
      data: JSON.stringify({
        wabaID: persondata.WABA_ID,
      }),
    };
    const fetchData = async () => {
      try {
        axios(config).then((res) => {
          setMyChats(res.data);
          //console.log(res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
}

// Create a custom hook to consume the fetched data

export default FetchChats;
