import { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { mymetrics } from "../../App";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
Chart.register(CategoryScale, LinearScale, BarElement, Legend, Title, Tooltip);

// This is the metrics graph component
function MetricGraph({ date }) {
  console.log(date);

  const [metrics, setMetrics] = useContext(mymetrics);
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [templatesNames, setTemplatesNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // i'm using async function to wait for the data to come
        if (metrics && metrics["Not From Webhooks"]) {
          // Here we send data to extract template Names
          setTemplatesNames(templateNameExtractor(metrics["All"]));
          // Here we are sending data to structure data suitable for metrics Graph
          const resultArray = await metricDataStructuring(metrics["All"]);

          console.log("The result array is ", resultArray);
          setLabels(resultArray);

          setLoading(false);
          // data is sent to the the setLabels
        } else {
          console.log("Data not received");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [metrics]);

  // this below function captures the template names and their dates
  const templateNameExtractor = (data) => {
    const groupedData = Object.entries(data).reduce(
      // here the metric data is reduced to array of arrays.
      (result, [date, templates]) => {
        const formattedDate = date.substring(0, 10);
        const templateName = Object.keys(templates)[0];

        // this below line checks for the date in the result array
        const existingItem = result.find((item) => item.date === formattedDate);

        // if the date is found then template would be added to it
        if (existingItem) {
          existingItem.templateName.push(templateName);
        } else {
          // if the result doesn't match the date then a new object is created
          result.push({
            date: formattedDate,
            templateName: [templateName],
          });
        }

        return result;
      },
      [] // the reduce function will return an array of objects which we needed
    );

    return groupedData;
  };

  // this function structures data as we want for the graph to show
  const metricDataStructuring = (data) => {
    const templateCounts = {};
    // Loop through each timestamp in the current key
    Object.keys(data).forEach((timestamp) => {
      // Extract the date part of the timestamp
      const currentDate = timestamp.substring(0, 10);

      // If the date is not a key in templateCounts, initialize it to an object with counts
      if (!templateCounts[currentDate]) {
        templateCounts[currentDate] = {
          templatesSent: 0,
          sumAudience: 0,
          sumSuccess: 0,
          sumFailure: 0,
          read: {},
          response: {},
        };
      }

      // Loop through each type of template in the current timestamp
      Object.keys(data[timestamp]).forEach((templateType) => {
        // Add the counts of audience, success, and failure for the current template type
        templateCounts[currentDate].sumAudience +=
          data[timestamp][templateType][0]["size of audience"];
        templateCounts[currentDate].sumSuccess +=
          data[timestamp][templateType][0].success;
        templateCounts[currentDate].sumFailure +=
          data[timestamp][templateType][0].failure;

        // Add the read and response structures for the current template type
        templateCounts[currentDate].read[timestamp] =
          data[timestamp][templateType][0].read;

        templateCounts[currentDate].response[timestamp] =
          data[timestamp][templateType][0]["response ratio"];
      });
    });

    // Convert the templateCounts object to an array of objects
    const resultArray = Object.keys(templateCounts).map((date) => ({
      date,
      sumAudience: templateCounts[date].sumAudience,
      sumSuccess: templateCounts[date].sumSuccess,
      sumFailure: templateCounts[date].sumFailure,
      read: templateCounts[date].read,
      response: templateCounts[date].response,
    }));
    console.log(resultArray);

    return resultArray;
  };

  const datasets = [
    {
      label: "Total Audience",
      data: labels.map((label) => label.sumAudience),
      backgroundColor: "#0dc16b",
    },
    {
      label: "No of campaigns",
      data: templatesNames.map(
        (templatesSent) => templatesSent.templateName.length
      ),
      backgroundColor: "#f4c542",
    },
    {
      label: "Success",
      data: labels.map((label) => label.sumSuccess),
      backgroundColor: "#3f51b5",
    },
    {
      label: "Failure",
      data: labels.map((label) => label.sumFailure),
      backgroundColor: "#f44336",
    },
  ];

  const data = {
    // below labels are the names that we see below graphs they state the date of data sent
    labels: labels.map((obj) => obj.date),
    datasets: datasets,
  };

  // this is the custom options of the graph
  const options = {
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tracker",
      },
    },
  };

  console.log("my data we are providing as input", data);

  const chartContent = !loading ? (
    <Bar data={data} options={options} />
  ) : (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  return <div className="mt-5 p-3">{chartContent}</div>;
}

export default MetricGraph;
