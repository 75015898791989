import React, { useState } from "react";
import FetchChats from "./fetchChats";
import { createContext } from "react";
import UserProfiles from "./userProfiles";
import IntialChatScreen from "./intialChatScreen";

export const chats = createContext();

function Main() {
  const [chatData, setChatData] = useState("no data");
  return (
    <chats.Provider value={[chatData, setChatData]}>
      <FetchChats />
      <UserProfiles />
    </chats.Provider>
  );
}

export default Main;
