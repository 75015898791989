import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function CalendarSelector({ setDate }) {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  // as the name suggests
  const handleDateChange = (date) => {
    if (selectedDates.length === 2) {
      setSelectedDates([date]);
    } else {
      setSelectedDates([...selectedDates, date]);
    }
    // Make sure to set the mode to "date" when a date is selected
  };

  const handleMonthChange = (month) => {
    if (selectedMonths.length === 2) {
      setSelectedMonths([month]);
    } else {
      setSelectedMonths([...selectedMonths, month]);
    }
    // Set the mode to "month" when a month is selected
  };

  useEffect(() => {
    setDate(finaloutput); // sends the selected dates to fetch metrics
  }, [selectedDates, selectedMonths]);

  // this selects the user selected date
  const finaloutput =
    selectedDates.length > 1
      ? selectedDates[1]
      : selectedDates.length > 0
      ? selectedDates[0]
      : "No date selected";

  return (
    <div className="ps-3">
      <h3 className="mb-3">Date Range Selector</h3>
      <p>Select any range of dates from here to find analytics</p>
      <Calendar
        onChange={handleDateChange}
        onClickMonth={handleMonthChange}
        selectRange
        defaultValue={new Date()}
      />
      {/* <div>
        {finaloutput.toString()}
      </div> */}
    </div>
  );
}

export default CalendarSelector;
