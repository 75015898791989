import React from "react";
import { useLocation } from "react-router-dom";
import FormTemplate from "./formTemplate";

import * as Tick from "react-icons/ti";
import Header from "../../header";

// this is the main file for create template all the components are assembled here

function TemplateCreator(props) {
  // myData comes from the users selection of template in the template category selector
  const location = useLocation();
  const myData = location.state?.myCategory;

  return (
    <div className="col-10 myWidth body">
      <Header title="Create Template" />
      <div style={{ backgroundColor: "#D2FFD6" }}>
        <FormTemplate myData={myData} />
      </div>
    </div>
  );
}

export default TemplateCreator;
