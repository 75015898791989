import React, { useEffect, useState, useContext, useMemo } from "react";
import axios from "axios";
import { persondata } from "../../Authentication/FetchedUserData";
import { apiRoutes } from "../../apiRoutes";
import { mymetrics } from "../../App";

function FetchMetrics({ dates }) {
  const [metrics, setMetrics] = useContext(mymetrics);
  const [date] = useState(new Date()); // No need for setDate if we don't modify it
  const [frmDate, setFromDate] = useState("");
  const [enDate, setEndDate] = useState("");

  // Memoize the current date to avoid recalculating it
  const currentDate = useMemo(() => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  }, [date]);

  // Memoized function to calculate the date 30 days ago
  const calculateLast30thDate = useMemo(() => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - 30);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  }, [currentDate]);

  // Memoize the date changer function to avoid unnecessary updates
  const dateChanger = useMemo(
    () => (changeDateFormat) => {
      if (
        changeDateFormat !== "No date selected" &&
        changeDateFormat !== "undefined" &&
        Array.isArray(changeDateFormat) &&
        changeDateFormat.length >= 2
      ) {
        const startDate = new Date(changeDateFormat[0]);
        const myEndDate = new Date(changeDateFormat[1]);

        const newFromDate = `${startDate.getFullYear()}-${(
          startDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${startDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        const newEndDate = `${myEndDate.getFullYear()}-${(
          myEndDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${myEndDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        setFromDate(newFromDate);
        setEndDate(newEndDate);
      } else {
        setFromDate(calculateLast30thDate);
        setEndDate(currentDate);
      }
    },
    [calculateLast30thDate, currentDate]
  );

  useEffect(() => {
    dateChanger(dates);
  }, [dates, dateChanger]);

  // Memoized API configuration
  const apiConfig = useMemo(() => {
    return {
      method: "post",
      url: apiRoutes.metrics,
      data: JSON.stringify({
        wabaID: persondata.WABA_ID,
        from_dateTime: frmDate + " 00:00:00",
        to_dateTime: enDate + " 23:59:59",
      }),
    };
  }, [frmDate, enDate]);

  // Fetch data only when the dates are valid and metrics are not already fetched
  useEffect(() => {
    async function fetchData() {
      if (!frmDate || !enDate) return; // Guard clause to prevent execution if dates aren't set
      try {
        const response = await axios(apiConfig);
        console.log("The response data from backend", response.data);
        setMetrics(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [frmDate, enDate, apiConfig, setMetrics]);

  return null;
}

export default FetchMetrics;
