import Dashboard from "./pages/dashboard";
import Status from "./pages/status";
import Sidebar from "./components/sidebar";
import CreateTemplate from "./pages/createTemplate";
import TrailDashboard from "./components/dasboard/trailDashboard/14daystrail";

// The below are the login flow routes
import Trailexpired from "./Authentication/loginPage/loginRoutes/trailexpired";
import ForgotPassword from "./Authentication/loginPage/loginRoutes/forgotPassword";
import NoAccount from "./Authentication/loginPage/loginRoutes/noAccount";
import TemplateCreator from "./components/Messagingpanel/createTemplate/createTemplate";
import FormSubmitNotification from "./components/Messagingpanel/createTemplate/formSubmitNotification";
import UserReg from "./Authentication/registrationAuth/userRegister";
import Phonedata from "./components/Messagingpanel/sendmessages/sendMessage";
import ChatMain from "./pages/chatbot";
import AllTemplates from "./pages/allTemplates";
import MyAccount from "./pages/myAccount";
// these below flows are the public flows

export const publicRoutes = [
  {
    path: "/login/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/trailExpired",
    element: <Trailexpired />,
  },
  {
    path: "/noAccount",
    element: <NoAccount />,
  },
  {
    path: "/userregistration",
    element: <UserReg />,
  },
];

// this below flows are private flows
export const privateRoutes = [
  {
    path: "/daystrial",
    element: <TrailDashboard />,
  },
  {
    path: "/",
    element: (
      <div className="row">
        <Sidebar />
        <Dashboard class="col-lg-10 myWidth body" />
      </div>
    ),
  },
  {
    path: "/status",
    element: (
      <div className="row">
        <Sidebar />
        <Status class="col-lg-10 myWidth body" />
      </div>
    ),
  },
  {
    path: "/createTemplate",
    element: <CreateTemplate />,
  },
  {
    path: "/createTemplate/templatecreator",
    element: (
      <div className="row">
        <Sidebar />
        <TemplateCreator />
      </div>
    ),
  },

  {
    path: "/createTemplate/templatesubmission",
    element: (
      <div className="row">
        <Sidebar /> <FormSubmitNotification />
      </div>
    ),
  },
  {
    path: "/allTemplates",
    element: <AllTemplates />,
  },
  {
    path: "/myaccount",
    element: <MyAccount />,
  },
  {
    path: "/components/Messagingpanel/sendmessages/phonedata",

    element: (
      <div className="row">
        <Sidebar /> <Phonedata />
      </div>
    ),
  },
  {
    path: "/chats",

    element: (
      <div className="row">
        <Sidebar /> <ChatMain />
      </div>
    ),
  },
];
