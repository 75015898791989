import React from "react";
import { useState, useRef } from "react";
import { actions, inputConfig, templateCategorytext } from "../data";
import axios from "axios";
import { persondata } from "../../../Authentication/FetchedUserData";
import TextHeader from "./ui/textHeader";
import useVisibility from "./../../setVisibleHook";
import BodyForm from "./bodyform";
import CreatedTemplateLook from "./createdTemplateLook";
import { useNavigate, useLocation } from "react-router-dom";
import { FloatingLabel } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlusSquare } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { apiRoutes } from "../../../apiRoutes";
import FileUpload from "./fileUpload";
import FormSubmitNotification from "./formSubmitNotification";

// for creation of template whatsapp Account id is required

function FormTemplate(props) {
  const formData = new FormData();
  const [media, setMedia] = useState();
  const [data, setData] = useState({
    templateCategory: props.myData,
    templateName: "",
    headerType: "Text",
    headerText: "",
    headerSample: "",
    bodySampleText1: null,
    bodySampleText2: null,
    bodySampleText3: null,
    bodySampleText4: null,
    bodySampleText5: null,
    bodySampleText6: null,
    bodySampleText7: null,
    bodySampleText8: null,
    bodySampleText9: null,
    bodySampleText10: null,
    bodySampleText11: null,
    bodySampleText12: null,
    bodySampleText13: null,
    bodySampleText14: null,
    bodySampleText15: null,
    bodySampleText16: null,
    bodySampleText17: null,
    bodySampleText18: null,
    bodySampleText19: null,
    bodySampleText20: null,
    body: "",
    footer: "",
    btnType: "", // quick reply or call to action
    phoneNumber: "",
    url: "",
    phoneNumberBtnText: "",
    urlBtnText: "",
    btn1: "",
    btn2: "",
    btn3: "",
    mediaFileType: "",
    mediaFileContentLength: "",
    permanentToken: persondata.permanenttoken,
    appId: persondata.appId,
    phoneNoId: persondata.phoneNo_id,
    templateLanguage: "en_US",
    wabaId: persondata.WABA_ID,
  });

  const { tisVisible, toggle, show, hide } = useVisibility(false); // this is the way to use the custom hooks
  const [variables, setVariables] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [templateSuccess, setTemplateSuccess] = useState();
  const [emoji, setEmoji] = useState(false);
  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const [errors, setErrors] = useState({
    templateName: "",
    body: "",
    bodySample: "",
  });

  const location = useLocation();
  const editableTemplateData = location.state?.editableTemplate;
  useEffect(() => {
    console.log(editableTemplateData);
  }, [editableTemplateData]);

  // This function records on changes and updates the data use state
  const handleForm = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
    console.log(data);
  };

  // This function is used for adding variable at both header and body
  // this function is added to both body and the header forms
  const addVariable = (ref) => (event) => {
    event.preventDefault();
    if (ref.current.name === "headerText") {
      ref.current.value += " {{v}} ";
      show();
      setData((x) => ({
        ...data, // the previous data is kept same and we are only updating headerText
        headerText: ref.current.value,
      }));
      event.target.name === "remove" && hide(); // This hides the button add variable
      console.log("variable added", data);
    } else if (ref.current.name === "bodyText") {
      event.preventDefault();
      if (variables.length < 20) {
        const variable = " {{v}} ";
        // Get the current cursor position for bodyText textarea
        const startPos = ref.current.selectionStart;
        const endPos = ref.current.selectionEnd;
        const newValue =
          ref.current.value.substring(0, startPos) +
          variable +
          ref.current.value.substring(endPos);

        // Update the textarea value and variables state
        ref.current.value = newValue;
        setVariables([...variables, variable]);

        // Update the data state
        setData((prevState) => ({
          ...prevState,
          body: newValue,
        }));
      }
    } else if (ref.current.name === "aiRef") {
      const variablesPresent = findingTheVariables(ref.current.value);
      const variable = " {{v}} ";
      if (variablesPresent < 20) {
        // Get the current cursor position for bodyText textarea
        const startPos = ref.current.selectionStart;
        const endPos = ref.current.selectionEnd;
        const newValue =
          ref.current.value.substring(0, startPos) +
          variable +
          ref.current.value.substring(endPos);
        // Update the textarea value and variables state
        ref.current.value = newValue;
      } else {
        alert("you can only add 20 variables");
      }
    }
  };

  // Define a single function to remove variables
  const removeVariable = (ref, arg) => (event) => {
    event.preventDefault();
    if (arg === "header") {
      ref.current.value = ref.current.value.replace("{{v}}", "");
      setData((x) => ({
        ...data,
        headerText: ref.current.value,
        headerSample: "",
      }));
    } else if (arg === "body") {
      setData((x) => ({ ...data, [arg]: "" }));
      setVariables(variables.slice(0, -1));
      setData((x) => ({
        ...data,
        body: data.body.replace(variables[variables.length - 1], ""),
      }));
    }

    hide();
  };

  // this function finds the number of variables in the body and returns the number
  const findingTheVariables = (x) => {
    const splittedData = x.split(" ");
    const varCount = splittedData.filter((x) => x === "{{v}}").length;
    return varCount;
  };

  // this function checks for {{v}} in body if does't exist reduces the variable array size
  // if {{v}} present in the body it adds the variable to the to setvariables to create the bodysampletext inputs
  const checkForVariable = (event) => {
    const varCount = findingTheVariables(data.body);
    if (event) {
      if (event.key === "Backspace" && varCount < variables.length) {
        console.log(variables.length - varCount);
        setVariables(variables.slice(0, -(variables.length - varCount)));
      }
    } else if (!event && variables.length < 10) {
      varCount === variables.length
        ? console.log("working")
        : setVariables([...variables, "{{v}}"]);
    }
  };

  //This function works when backspace is pressed for header
  const removeHeadVariable = (event) => {
    if (event.key === "Backspace") {
      data.headerText.indexOf("{{v}}") === -1 ? hide() : "";
    }
  };

  // the below two functions are for emoji getting and storing
  const handleEmojiButtonClick = (e) => {
    e.preventDefault();
    setEmoji(!emoji);
  };

  const handleEmojiClick = (emoji) => {
    setData((x) => ({ ...data, body: data.body + emoji.native }));
  };

  const insertAiContent = (ref) => {
    const totalVariables = findingTheVariables(ref.current.value);
    console.log(totalVariables);
    const newVariables = [];
    const v = "{{v}}";
    for (let i = 0; i < totalVariables; i++) {
      newVariables.push(v);
    }
    setVariables(newVariables);
    setData({ ...data, body: ref.current.value });
    bodyRef.current.value = ref.current.value;
  };

  // When a file is uploaded it is updated to the data object to be sent
  useEffect(() => {
    media &&
      setData((x) => ({
        ...data,
        mediaFileType: media.type,
        mediaFileContentLength: media.size,
      }));
  }, [media]);

  // this submits the form data to the muleserver
  const submitForm = (e) => {
    e.preventDefault();
    // Remove spaces and convert to lowercase for the template name
    data.templateName = data.templateName.replace(/\s+/g, "_").toLowerCase();

    // Check for template name length
    if (data.templateName.length < 3) {
      setErrors({ ...errors, templateName: "Please enter the template name" });
      return;
    }

    // Validate variables
    let allVariablesValid = true;
    if (variables.length > 0) {
      variables.forEach((x, index) => {
        if (!data[`bodySampleText${index + 1}`]) {
          alert(`Please enter the value in variable ${index + 1}`);
          allVariablesValid = false;
        }
      });
    }

    // Call sendingToBackend if all validations pass
    if (allVariablesValid) {
      sendingToBackend();
    }
  };

  const sendingToBackend = () => {
    console.log("number of times called");
    if (media) {
      console.log("the data is ", data);
      formData.append("mediaFileData", media);
      formData.append("jsonData", JSON.stringify(data));
      const config1 = {
        method: "post",
        url: apiRoutes.createTemplateLatest,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      };
      // console.log(formData);
      // console.log(config1);
      axios(config1)
        .then((res) => {
          console.log(res);
          setTemplateSuccess(res);
        })
        .catch((err) => console.log(err));
      setIsVisible(true);
    } else {
      const config = {
        method: "post",
        url: apiRoutes.createTemplateLatest,
        data: data,
      };
      console.log(config);
      axios(config)
        .then((res) => {
          setTemplateSuccess(res);
          console.log(res);
        })
        .catch((err) => console.log(err));
      setIsVisible(true);
    }
  };
  return (
    <div className="row">
      <div className="col ms-2">
        <form className="px-4 mediaBack">
          <h5 className="formHeading">
            <span className=" mx-1 rounded">{props.myData}</span>
            Template
          </h5>
          {/* // This below code is for template name */}
          <div className="form-group mt-4">
            <FloatingLabel
              controlId="templateName"
              label="Template Name"
              className="spacing"
            >
              <Form.Control
                type="text"
                placeholder="Enter the Name of Template"
                onChange={handleForm}
              />
              <p className="userErrors">{errors.templateName}</p>
            </FloatingLabel>
            <div className="ms-1">
              <h5>Header:</h5>
              <p>
                Add a title or choose which type of media you’ll use for the
                header
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <select
                className="mt-1 dropdown form-select custom-select"
                id="headerType"
                onChange={handleForm}
              >
                {actions.slice(0, 5).map((x, index) => {
                  return <option key={index}>{x.name}</option>;
                })}
              </select>

              {data.headerType === "Text" ? (
                !tisVisible ? (
                  <>
                    <button
                      className="btn mt-1 addVariable text-white"
                      onClick={addVariable(headerRef)}
                    >
                      <AiOutlinePlusSquare className="me-2" />
                      Add Variable
                    </button>
                  </>
                ) : (
                  <button
                    className="btn addVariable mt-2 text-white"
                    name="remove"
                    onClick={removeVariable(headerRef, "header")}
                  >
                    <AiOutlineMinus className="me-2" />
                    Remove Variable
                  </button>
                )
              ) : (
                ""
              )}
            </div>
            {/* this is the entire header component */}
            <div className="mt-2 ">
              {data.headerType === "Image" && (
                <FileUpload fileType={"Image"} setMedia={setMedia} />
              )}
              {data.headerType === "Video" && (
                <FileUpload fileType={"Video"} setMedia={setMedia} />
              )}
              {data.headerType === "Pdf" && (
                <FileUpload fileType={"Document"} setMedia={setMedia} />
              )}
              {data.headerType === "Text" && (
                <div>
                  <FloatingLabel
                    controlId={"headerText"}
                    label={"Header Text"}
                    className="headerText mb-1"
                  >
                    <Form.Control
                      name={"headerText"}
                      type="text"
                      ref={headerRef}
                      placeholder={"Header Text"}
                      onKeyUp={removeHeadVariable}
                      defaultValue={data["headerText"]}
                      onChange={handleForm}
                      maxLength={60}
                    />
                  </FloatingLabel>
                  {tisVisible && (
                    <TextHeader
                      class=""
                      headerRef={headerRef}
                      label={"Sample text "}
                      headerText={data["headerText"]}
                      id={"headerSample"}
                      handleForm={handleForm}
                      addVariable={addVariable}
                      placeholder={`Provide sample text for here`}
                    />
                  )}
                </div>
              )}

              <BodyForm
                handleForm={handleForm}
                checkForVariable={checkForVariable}
                addVariable={addVariable}
                handleEmojiButtonClick={handleEmojiButtonClick}
                handleEmojiClick={handleEmojiClick}
                emoji={emoji}
                setVariables={setVariables}
                variables={variables}
                removeBodyVariable={removeVariable}
                bodyRef={bodyRef}
                insertAi={insertAiContent}
                data={data}
                setData={setData}
              />
            </div>
            <div className="text-end me-2">
              <button
                type="submit"
                className="btn text-white"
                id="submitBtn"
                onClick={submitForm}
              >
                Submit Template
              </button>
            </div>
          </div>
        </form>
      </div>

      <FormSubmitNotification
        status={templateSuccess}
        show={isVisible}
        setShow={setIsVisible}
      />

      <div className="col" id="createdTemplateLook">
        <CreatedTemplateLook templateData={data} media={media} preview="true" />
      </div>
    </div>
  );
}

export default FormTemplate;
