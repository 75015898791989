// This is an floating label input component
function FloatingInput(props) {
  const { id, type, name, onChange, placeholder, label } = props;
  return (
    <div className="form-floating">
      <input
        id={id}
        type={type}
        className="form-control mt-3"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required
      />
      <label htmlFor="floatingInput">{label}</label>
    </div>
  );
}

export default FloatingInput;
