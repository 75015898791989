// this component calculates the variables present

export const Collecting = (text) => {
  // console.log(text);
  const regex = /\{\{(\d+)\}\}/g; // matches {{number}}
  let match;
  let count = 0;
  while ((match = regex.exec(text)) !== null) {
    count++;
  }
  return count;
};
