import React from "react";
import { apiRoutes } from "../../apiRoutes";
import { Card, Button } from "react-bootstrap";

function ChangePassword() {
  // change password
  const config5 = {
    method: "post",
    url: apiRoutes.forgotPassword,
    data: JSON.stringify({
      email: "",
      apiKey: "JayJaggu",
    }),
  };

  return (
    <div className="col">
      <Card className="billingCard mx-2">
        <Card.Body>
          <Card.Title>Change Password</Card.Title>
          <Card.Text>Feel free to change your password</Card.Text>
          <Button variant="primary">Reset Password</Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ChangePassword;
