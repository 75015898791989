// this code is for creating text header

import { FloatingLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";

//this code only works for variables in text header
function TextHeader(props) {
  return (
    <>
      <FloatingLabel
        controlId={props.id}
        label={props.label}
        className={props.class}
      >
        <Form.Control
          name="headerSample"
          type="text"
          placeholder={props.placeholder}
          onChange={props.handleForm}
        />
      </FloatingLabel>
    </>
  );
}

export default TextHeader;
